import React, { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHandlerOrderContext } from "./providers/Handler";
import { useAppContext } from "../../AppProvider";
import {
  faArrowLeft,
  faEuro,
  faICursor,
  faInfoCircle,
  faLoader,
  faPercentage,
  faWeightHanging,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { dictionary } from "../../utilities/dictionary";
import AsyncSelect from "../../Components/common/AsyncSelect/AsyncSelect";
import Select from "../../Components/common/Selects/Select";
import {
  getAllCashOnDeliveryCollectionMethods,
  getAllWarehouses,
} from "../../utilities/asyncSelectCallAPI";
import { getWarehouseOptionStyle } from "../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../utilities/types";
import OrderDetails from "../OrderDetails";
import { IndexOrderDetailsProvider } from "../OrderDetails/providers/Index";
import ContactHandler from "../Contacts/ContactHandler";
import { ContactHandlerProvider } from "../Contacts/providers/Handler";
import {
  isDisabledConfirm,
  isDisabledDelete,
  isDisabledProcess,
} from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import { ConfirmOrdersProvider } from "./providers/Confirm";
import { DeleteOrdersProvider } from "./providers/Delete";
import { ProcessOrdersProvider } from "./providers/Process";
import Confirm from "./Confirm";
import Delete from "./Delete";
import Process from "./Process";
import Rate from "./Rate";
import moment from "moment";
import InputBoxIcon from "../../Components/common/InputBoxIcon";

const OrderHandler = () => {
  const action = window.location.pathname.split("/")[2];
  const { id } = useParams();
  const navigate = useNavigate();
  const types = useTypes();
  const appContext = useAppContext();
  const language = appContext?.language;

  let context = useHandlerOrderContext();

  let title = `${dictionary["new"][language]} ${dictionary["order"][
    language
  ].toLowerCase()}`;
  switch (action) {
    case "clone":
      title = `${dictionary["clone"][language]} ${dictionary["order"][
        language
      ].toLowerCase()}`;
      break;
    case "edit":
      title = `${dictionary["edit"][language]} ${dictionary["order"][
        language
      ].toLowerCase()}`;
      break;
    case "details":
      title = `${dictionary["details"][language]} ${dictionary["order"][
        language
      ].toLowerCase()}`;
      break;
  }

  const handleChange = (name, value, order = { ...context?.order }) => {
    if (action !== "details") {
      const newOrder = {
        ...order,
        [name]: value,
      };

      context?.editOrder(newOrder);
      context?.removeError(name);
    }
  };

  const renderOrderDetailsFooter = () => {
    return (
      <React.Fragment>
        <div
          className=" d-grid grid-column align-items-start px-2"
          style={{
            gridTemplateColumns: `0.25fr 1.5fr 2.5fr 0.75fr 1fr 1fr 1fr 0.75fr 1.5fr 1.5fr`,
          }}
        >
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary["total_weight"][language]}:
            </label>
            <InputBoxIcon
              name="totalWeight"
              value={context?.order.totalWeight || ""}
              type="positive-number"
              placeholder=""
              precision={3}
              onChange={(e) => handleChange(e.target.name, e.target.value)}
              disabled={action === "details"}
              error={context?.orderError?.response?.data?.totalWeight}
              icon={faWeightHanging}
            />
          </div>
          <div></div>
          <div></div>
          <div>
            <label className="label-2">
              {dictionary["total_price"][language]}:
            </label>
            <InputBoxIcon
              name="totalPrice"
              value={context?.order.totalPrice || ""}
              type="number"
              placeholder=""
              onChange={(e) => {
                const { value, name } = e.target;
                const newOrder = {
                  ...context?.order,
                  goodsValue: value,
                  insuranceValue: value,
                  cashOnDeliveryValue:
                    (parseFloat(value || 0) +
                      parseFloat(context?.order?.shippingCost || 0)) *
                    1.22,
                };
                handleChange(name, value, newOrder);
              }}
              disabled={action === "details"}
              error={context?.orderError?.response?.data?.totalPrice}
              icon={faEuro}
              precision={2}
            />
          </div>
          <div>
            <label className="label-2">
              {dictionary["total_price"][language]} (+IVA 22%):
            </label>
            <InputBoxIcon
              value={
                ((context?.order?.totalPrice || 0) * 1.22).toFixed(2) || ""
              }
              type="positive-number"
              placeholder=""
              disabled
              readOnly
              icon={faEuro}
            />
          </div>
        </div>
        <hr></hr>
      </React.Fragment>
    );
  };

  useEffect(() => {
    if (action === "create") {
      context?.createOrder();
      return;
    } else if (action === "clone") {
      context?.cloneOrder();
      return;
    }
    context?.getOrder(id);
  }, []);

  const status = types.orderStatus.find(
    (s) => s.value === context?.order?.lastStatus
  );
  const shipment = context?.order?.shipments?.[0]?.lastStatusName
    ? context?.order?.shipments[0]
    : null;

  return (
    <React.Fragment>
      <div className="row align-items-center mb-2">
        <div className="col">
          <h2 className="m-0">{title}</h2>
        </div>
        <div className="col-3">
          <div
            style={{
              backgroundColor: shipment
                ? shipment.lastStatusColor
                : status?.color,
            }}
            className={`status w-auto`}
          >
            <span className={`name`}>
              {shipment ? shipment.lastStatusName : status?.label}
            </span>{" "}
            {shipment?.deliveryDate && (
              <>
                <FontAwesomeIcon
                  id={"deliveryDate_"}
                  className="cursor-pointer"
                  icon={faInfoCircle}
                />{" "}
                <UncontrolledTooltip
                  placement="bottom"
                  target={"deliveryDate_"}
                >
                  {moment(shipment.deliveryDate).format("DD/MM/YYYY HH:mm")}
                </UncontrolledTooltip>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="row mb-2">
        <div className="col">
          <div className="box-container h-100 mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <h3 className="m-0">{dictionary["general_infos"][language]}</h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["customer_reference"][language]}
                    </label>
                    <InputBoxIcon
                      name="externalReference"
                      value={context?.order?.externalReference || ""}
                      placeholder={action !== "details" ? "EXTREF123" : ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.externalReference
                      }
                      icon={faICursor}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["logistic_reference"][language]}
                    </label>
                    <InputBoxIcon
                      name="logisticReference"
                      value={context?.order?.logisticReference || ""}
                      placeholder={action !== "details" ? "-" : ""}
                      type="text"
                      readOnly={true}
                      disabled={true}
                      icon={faICursor}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["allabox_reference"][language]}
                    </label>
                    <InputBoxIcon
                      name="internalReference"
                      value={context?.order?.internalReference || ""}
                      placeholder={action !== "details" ? "EXTREF123" : ""}
                      type="text"
                      readOnly={true}
                      disabled={true}
                      icon={faICursor}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["warehouse"][language]}
                    </label>
                    <AsyncSelect
                      name="warehouse"
                      optionValue="id"
                      optionLabel="name"
                      value={context?.order?.warehouse}
                      onSearch={(selector, value) => {
                        handleChange(selector, value);
                      }}
                      loadOptions={(inputValue, pagination) => {
                        return getAllWarehouses(inputValue, pagination, {
                          orderBy: "asc",
                          selector: "name",
                        });
                      }}
                      optionStyle={getWarehouseOptionStyle}
                      isClearable={false}
                      isDisabled={action === "details"}
                      error={context?.orderError?.response?.data?.warehouse}
                      placeholder={action !== "details" ? "Select" : ""}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary["additional_infos"][language]}
                </h3>
                <div
                  className="d-grid grid-column mt-2"
                  style={{
                    gridTemplateColumns: `0.5fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["vat_regime"][language]}
                    </label>
                    <InputBoxIcon
                      name="vatRegime"
                      value={"22"}
                      type="text"
                      readOnly={true}
                      icon={faPercentage}
                      disabled={true}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["payment_method"][language]}
                    </label>
                    <InputBoxIcon
                      name="paymentMethod"
                      value={context?.order.paymentMethod || ""}
                      type="text"
                      onChange={(e) => {
                        handleChange(e.target.name, e.target.value);
                      }}
                      disabled={action === "details"}
                      icon={faICursor}
                      error={context?.orderError?.response?.data?.paymentMethod}
                    />
                  </div>
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary["shipment_infos"][language]}
                </h3>
                <div
                  className="d-grid grid-column align-items-end mt-2"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["goods"][language]} (IVA{" "}
                      {dictionary["excluded_fem"][language]}){" "}
                      <FontAwesomeIcon
                        id={"goodsValue_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"goodsValue_"}
                    >
                      {dictionary["calculated_logistics_goods_value"][language]}
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="goodsValue"
                      value={context?.order.goodsValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) => {
                        const { value, name } = e.target;
                        const newOrder = {
                          ...context?.order,
                          cashOnDeliveryValue:
                            (parseFloat(value || 0) +
                              parseFloat(context?.order?.shippingCost || 0)) *
                            1.22,
                        };
                        handleChange(name, value, newOrder);
                      }}
                      disabled={action === "details"}
                      icon={faEuro}
                      error={context?.orderError?.response?.data?.goodsValue}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["insurance"][language]} (IVA{" "}
                      {dictionary["excluded_fem"][language]}){" "}
                      <FontAwesomeIcon
                        id={"insuranceValue_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"insuranceValue_"}
                    >
                      {
                        dictionary["calculated_logistics_insurance_value"][
                          language
                        ]
                      }
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="insuranceValue"
                      value={context?.order.insuranceValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.insuranceValue
                      }
                      icon={faEuro}
                    />
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["value"][language]}{" "}
                      {dictionary["cash_on_delivery"][language]} (IVA{" "}
                      {dictionary["included_fem"][language]}){" "}
                      <FontAwesomeIcon
                        id={"cash_on_delivery_"}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />
                    </label>
                    <UncontrolledTooltip
                      placement="bottom"
                      target={"cash_on_delivery_"}
                    >
                      {dictionary["calculated_logistics_cod_value"][language]}
                    </UncontrolledTooltip>
                    <InputBoxIcon
                      name="cashOnDeliveryValue"
                      value={context?.order.cashOnDeliveryValue || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) =>
                        handleChange(e.target.name, e.target.value)
                      }
                      disabled={action === "details"}
                      error={
                        context?.orderError?.response?.data?.cashOnDeliveryValue
                      }
                      icon={faEuro}
                    />
                  </div>
                </div>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["currency"][language]}
                    </label>
                    <Select
                      name="currency"
                      onChange={(option) => {
                        handleChange("currency", option);
                      }}
                      defaultMenuIsOpen={false}
                      isDisabled={action === "details"}
                      className="select"
                      options={types.currencies2}
                      value={context?.order?.currency}
                    />
                  </div>
                  {context?.order?.cashOnDeliveryValue > 0 && (
                    <div>
                      <label className="label-2">
                        {dictionary["cod_method"][language]}
                      </label>
                      <AsyncSelect
                        name="cashOnDeliveryCollectionMethodCode"
                        optionValue="code"
                        optionLabel="name"
                        value={
                          context?.order?.cashOnDeliveryCollectionMethodCode
                        }
                        onSearch={(selector, value) =>
                          handleChange(selector, value)
                        }
                        loadOptions={(inputValue, pagination) => {
                          return getAllCashOnDeliveryCollectionMethods(
                            inputValue,
                            pagination,
                            {
                              orderBy: "asc",
                              selector: "name",
                            }
                          );
                        }}
                        isDisabled={action === "details"}
                        isClearable={false}
                      />
                    </div>
                  )}
                </div>
                <h3 className="m-0 mt-2">
                  {dictionary["billing_infos"][language]}
                </h3>
                <div
                  className="d-grid grid-column mt-1"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["shipment_price"][language]} (IVA{" "}
                      {dictionary["excluded_fem"][language]})
                    </label>
                    <InputBoxIcon
                      name="shippingCost"
                      value={context?.order.shippingCost || ""}
                      type="positive-number"
                      placeholder={action !== "details" ? "100.00" : ""}
                      onChange={(e) => {
                        const { value, name } = e.target;
                        const newOrder = {
                          ...context?.order,
                          cashOnDeliveryValue:
                            (parseFloat(value || 0) +
                              parseFloat(context?.order?.goodsValue || 0)) *
                            1.22,
                        };
                        handleChange(name, value, newOrder);
                      }}
                      disabled={action === "details"}
                      error={context?.orderError?.response?.data?.shippingCost}
                      icon={faEuro}
                    />
                  </div>
                </div>
              </React.Fragment>
            )}
          </div>
        </div>
        <div className="col">
          <div className="box-container h-100 mb-2">
            {context?.orderLoader ? (
              <div className="row">
                <div className="col text-center">
                  <FontAwesomeIcon icon={faLoader} spin size="2x" />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <ContactHandlerProvider
                  service="logistic"
                  id={context?.order?.delivery?.id}
                  callback={(contact) => {
                    const newOrder = { ...context?.order };
                    newOrder.delivery = contact;
                    newOrder.deliveryId = contact.id;
                    context?.editOrder(newOrder);
                  }}
                >
                  <ContactHandler
                    action={action}
                    billingSettings={{
                      enabled: true,
                      id: context?.order?.billing?.id,
                      onChange: (billingContact) => {
                        if (action !== "details") {
                          const newOrder = { ...context?.order };
                          newOrder.billing = context?.order?.delivery;
                          newOrder.billingId = context?.order?.delivery.id;

                          if (billingContact) {
                            newOrder.billing = billingContact;
                            newOrder.billingId = billingContact.id;
                          }
                          context?.editOrder(newOrder);
                        }
                      },
                    }}
                    title={
                      <h3 className="m-0">
                        {dictionary["receiver"][language]}
                      </h3>
                    }
                  />
                </ContactHandlerProvider>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>

      {context?.order?.id && (
        <IndexOrderDetailsProvider parentId={context?.order?.id}>
          <OrderDetails
            action={action}
            renderFooter={renderOrderDetailsFooter}
          />
        </IndexOrderDetailsProvider>
      )}

      <div className="row mt-3">
        <div className="col">
          {
            <Link to={`/orders`} className="button-1 button-1-transparent">
              <FontAwesomeIcon icon={faArrowLeft} />{" "}
              {dictionary["back"][language]}
            </Link>
          }
        </div>
        <div className="col text-end">
          {context?.order && !isDisabledProcess([context?.order]) && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: `${dictionary["process"][language]} ${dictionary[
                    "order"
                  ][language].toLowerCase()}`,
                  action: "process",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            >
              {dictionary["process"][language]}
            </ButtonConfirm>
          )}
          {context?.order && !isDisabledConfirm([context?.order]) && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary["confirm_order"][language],
                  action: "confirm",
                  data: [context?.order?.id],
                  size: "sm",
                });
              }}
            >
              {dictionary["confirm"][language]}
            </ButtonConfirm>
          )}
          {context?.order && !isDisabledDelete([context?.order]) && (
            <ButtonDelete
              className="ms-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary["confirm_deletion"][language],
                  action: "delete",
                  data: [context?.order],
                  size: "sm",
                });
              }}
            />
          )}
          {context?.createShipmentEnabled && (
            <ButtonConfirm
              className="me-1"
              onClick={() => {
                context?.setModal({
                  open: true,
                  title: dictionary["create_new_shipment"][language],
                  action: "createShipment",
                  order: context?.order,
                  size: "xl",
                });
              }}
            >
              {dictionary["create_new_shipment"][language]}
            </ButtonConfirm>
          )}
        </div>
      </div>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalBody>
          {context?.modal.action === "confirm" && (
            <ConfirmOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders/details/" + id, { replace: true });
                }
                context.setModal({ open: false });
              }}
            >
              <Confirm />
            </ConfirmOrdersProvider>
          )}
          {context?.modal.action === "process" && (
            <ProcessOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  context?.getOrder();
                }
                context.setModal({ open: false });
              }}
            >
              <Process />
            </ProcessOrdersProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteOrdersProvider
              data={context?.modal.data}
              callback={(response) => {
                if (response) {
                  navigate("/orders", { replace: true });
                }
                context.setModal({ open: false });
              }}
            >
              <Delete />
            </DeleteOrdersProvider>
          )}

          {context?.modal.action === "createShipment" && (
            <Rate
              callback={() => {
                context.setModal({ open: false });
                context?.getOrder();
              }}
            />
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default OrderHandler;

import React, { useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faInfoCircle,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { Modal, ModalBody, ModalHeader, UncontrolledTooltip } from "reactstrap";
import { ButtonDelete } from "../../Components/common/Buttons/ButtonDelete";
import { CreateEdit } from "./CreateEdit";
import { CreateEditWarehouseArticleProvider } from "./providers/CreateEdit";
import { DeleteWarehouseArticleProvider } from "./providers/Delete";
import Delete from "./Delete";
import { ButtonUtility } from "../../Components/common/Buttons/ButtonUtility";
import { useIndexWarehouseArticlesContext } from "./providers/Index";

const WarehouseArticles = ({ action, shippingRequired = true }) => {
  const listRef = useRef(null);
  const listProps = useListProps(action, shippingRequired);

  const context = useIndexWarehouseArticlesContext();

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} action={action} />;
  };

  return (
    <React.Fragment>
      <ListProvider
        path={listProps.path}
        parentId={context?.parentId}
        sorting={listProps.sorting}
        filters={listProps.filters}
        defaultDate={listProps.defaultDate}
      >
        <List
          ref={listRef}
          title={listProps.title}
          create={listProps.create}
          banner={listProps.banner}
          massiveActions={listProps.massiveActions}
          omnisearch={listProps.omnisearch}
          renderItem={renderItem}
          pagination={listProps.pagination}
          selectRowsEnabled={listProps.selectRowsEnabled}
          actionBar={listProps.actionBar}
        />
      </ListProvider>

      <Modal isOpen={context?.modal.open} size={context?.modal.size}>
        <ModalHeader
          toggle={() => {
            listRef?.current.refresh();
            context?.setModal({ open: false });
          }}
        >
          <span className="modal-title">
            <i className={context?.modal.icon + " me-2"}></i>
            {context?.modal.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {context?.modal.action === "create" && (
            <CreateEditWarehouseArticleProvider
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <CreateEdit
                parentId={context?.parentId}
                unmountCallback={() => {
                  listRef?.current.refresh();
                  context?.setModal({ open: false });
                }}
              />
            </CreateEditWarehouseArticleProvider>
          )}
          {context?.modal.action === "edit" && (
            <CreateEditWarehouseArticleProvider
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <CreateEdit
                parentId={context?.parentId}
                id={context?.modal.data}
                unmountCallback={() => {
                  listRef?.current.refresh();
                  context?.setModal({ open: false });
                }}
              />
            </CreateEditWarehouseArticleProvider>
          )}
          {context?.modal.action === "delete" && (
            <DeleteWarehouseArticleProvider
              data={context?.modal.data}
              unmountCallback={() => {
                listRef?.current.refresh();
                context?.setModal({ open: false });
              }}
            >
              <Delete />
            </DeleteWarehouseArticleProvider>
          )}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default WarehouseArticles;

const ListItem = React.memo(({ item, index, action }) => {
  const appContext = useAppContext();
  const language = appContext.language;

  const context = useIndexWarehouseArticlesContext();

  return (
    <React.Fragment key={index}>
      <div className="list-item px-1">
        {item.article.shippingRequired ? (
          <div
            className="d-grid grid-column text-center"
            style={{
              gridTemplateColumns: `2fr 1fr 1fr 4fr 4fr 1fr`,
            }}
          >
            <div>
              <div
                className="d-grid grid-column bg-color-4"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-1">
                  {dictionary["name"][language]}
                </label>
              </div>
              <div
                className="d-grid grid-column"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-3">{item.article.name}</label>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-between">
              <div
                className="d-grid grid-column bg-color-4"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-1">
                  {dictionary["coming_soon"][language]}
                </label>
              </div>
              <div
                className="d-grid grid-column"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-3">
                  {item.warehouseInfo.expectedInfo.incoming}
                </label>
              </div>
            </div>
            <div className="d-flex flex-column justify-content-end">
              <div>
                <div>
                  <label className="label-1 mb-1">
                    {dictionary["estimated"][language]}
                  </label>
                </div>
              </div>
              <div>
                <div>
                  <label className="label-1">
                    {dictionary["effective"][language]}
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div
                className="d-grid grid-column bg-color-4"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <div>
                  <label className="label-1">
                    {dictionary["in_stock"][language]}
                  </label>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["committed"][language]}
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["blocked"][language]}{" "}
                      <FontAwesomeIcon
                        id={"blocked_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"blocked_" + item.id}
                      >
                        {dictionary["not_available_for_sale"][language]}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["availables"][language]}
                    </label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-2">
                      {dictionary["total"][language]}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-3">
                      {item.warehouseInfo.expectedInfo.committed}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.warehouseInfo.expectedInfo.blocked}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.warehouseInfo.expectedInfo.available}
                    </label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-3">
                      {item.warehouseInfo.expectedInfo.total}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `1fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div></div>
                  <div>
                    <label className="label-3">
                      {item.warehouseInfo.effectiveInfo.blocked}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.warehouseInfo.effectiveInfo.available}
                    </label>
                  </div>
                  <div className="bg-color-4"></div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-color-4">
                <div>
                  <label className="label-1">
                    {dictionary["sold"][language]}
                  </label>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["lost"][language]}
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["out_of_delivery"][language]}{" "}
                      <FontAwesomeIcon
                        id={"out_of_delivery_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"out_of_delivery_" + item.id}
                      >
                        {dictionary["out_of_delivery"][language]}{" "}
                        {dictionary["at_customer"][language]}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["delivered_pm"][language]}{" "}
                      <FontAwesomeIcon
                        id={"delivered_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"delivered_" + item.id}
                      >
                        {dictionary["delivered_pm"][language]}{" "}
                        {dictionary["at_customer"][language]}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-2">
                      {dictionary["total"][language]}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-3">{item.soldInfo.lost}</label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.soldInfo.onDelivery}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">{item.soldInfo.delivered}</label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-3">{item.soldInfo.total}</label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className="bg-color-4">
                    <label> </label>{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {action !== "details" && (
                <React.Fragment>
                  <ButtonUtility
                    className="ms-1"
                    title={dictionary["edit"][language]}
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary["edit"][language]} ${dictionary[
                          "product"
                        ][language].toLowerCase()}`,
                        action: "edit",
                        data: item.id,
                        size: "md",
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ButtonUtility>
                  <ButtonDelete
                    className="ms-1"
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary["delete"][language]} ${dictionary[
                          "product"
                        ][language].toLowerCase()}`,
                        action: "delete",
                        data: [item.id],
                        size: "sm",
                      });
                    }}
                  >
                    {" "}
                  </ButtonDelete>
                </React.Fragment>
              )}
            </div>
          </div>
        ) : (
          <div
            className="d-grid grid-column text-center"
            style={{
              gridTemplateColumns: `2fr 9fr 1fr`,
            }}
          >
            <div>
              <div
                className="d-grid grid-column bg-color-4"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-1">
                  {dictionary["name"][language]}
                </label>
              </div>
              <div
                className="d-grid grid-column"
                style={{
                  gridTemplateColumns: `1fr`,
                }}
              >
                <label className="label-3">{item.article.name}</label>
              </div>
            </div>
            <div>
              <div className="bg-color-4">
                <div>
                  <label className="label-1">
                    {dictionary["sold"][language]}
                  </label>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-2">
                      {dictionary["lost"][language]}
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["out_of_delivery"][language]}{" "}
                      <FontAwesomeIcon
                        id={"out_of_delivery_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"out_of_delivery_" + item.id}
                      >
                        {dictionary["out_of_delivery"][language]}{" "}
                        {dictionary["at_customer"][language]}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div>
                    <label className="label-2">
                      {dictionary["delivered_pm"][language]}{" "}
                      <FontAwesomeIcon
                        id={"delivered_" + item.id}
                        className="cursor-pointer"
                        icon={faInfoCircle}
                      />{" "}
                      <UncontrolledTooltip
                        placement="bottom"
                        target={"delivered_" + item.id}
                      >
                        {dictionary["delivered_pm"][language]}{" "}
                        {dictionary["at_customer"][language]}
                      </UncontrolledTooltip>
                    </label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-2">
                      {dictionary["total"][language]}
                    </label>
                  </div>
                </div>
              </div>
              <div>
                <div
                  className="d-grid grid-column"
                  style={{
                    gridTemplateColumns: `0.75fr 1fr 1fr 0.5fr`,
                  }}
                >
                  <div>
                    <label className="label-3">{item.soldInfo.lost}</label>
                  </div>
                  <div>
                    <label className="label-3">
                      {item.soldInfo.onDelivery}
                    </label>
                  </div>
                  <div>
                    <label className="label-3">{item.soldInfo.delivered}</label>
                  </div>
                  <div className="bg-color-4 text-center">
                    <label className="label-3">{item.soldInfo.total}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end align-items-center">
              {action !== "details" && (
                <React.Fragment>
                  <ButtonUtility
                    className="ms-1"
                    title={dictionary["edit"][language]}
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary["edit"][language]} ${dictionary[
                          "product"
                        ][language].toLowerCase()}`,
                        action: "edit",
                        data: item.id,
                        size: "md",
                      });
                    }}
                  >
                    <FontAwesomeIcon icon={faEye} />
                  </ButtonUtility>
                  <ButtonDelete
                    className="ms-1"
                    onClick={() => {
                      context?.setModal({
                        open: true,
                        title: `${dictionary["delete"][language]} ${dictionary[
                          "product"
                        ][language].toLowerCase()}`,
                        action: "delete",
                        data: [item.id],
                        size: "sm",
                      });
                    }}
                  >
                    {" "}
                  </ButtonDelete>
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
});

import {
  faArrowDownShortWide,
  faArrowUpShortWide,
  faCalendar,
  faCheck,
  faChevronDoubleRight,
  faDownload,
  faDownToBracket,
  faFileCsv,
  faLoader,
  faMagnifyingGlass,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIndexOrdersContext } from "../providers/Index";
import {
  getAllCompanies,
  getAllWarehouses,
} from "../../../utilities/asyncSelectCallAPI";
import {
  getCompanyOptionStyle,
  getOrderStatusOptionStyle,
  getWarehouseOptionStyle,
} from "../../../Components/common/AsyncSelect/utilities";
import { useTypes } from "../../../utilities/types";
import moment from "moment";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

const statusValidForDelete = ["DRAFT", "CONFIRMED"];
const statusValidForConfirm = ["DRAFT"];
const statusValidForProcess = ["CONFIRMED"];

export const isDisabledDelete = (selectedRows) =>
  !selectedRows.every((item) =>
    statusValidForDelete.includes(item?.lastStatus)
  );

export const isDisabledConfirm = (selectedRows) =>
  !selectedRows.every(
    (item) =>
      statusValidForConfirm.includes(item?.lastStatus) &&
      item.delivery?.id &&
      item.orderDetails > 0
  );

export const isDisabledProcess = (selectedRows) => {
  const { accessTokenPayload } = useOidcAccessToken();
  return !selectedRows.every(
    (item) =>
      statusValidForProcess.includes(item?.lastStatus) &&
      accessTokenPayload.owner === "7f8625b9-973b-4c66-80af-346b49cde2e7"
  );
};

export const useListProps = (listRef) => {
  const { accessTokenPayload } = useOidcAccessToken();

  const navigate = useNavigate();

  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexOrdersContext();

  const types = useTypes();

  return {
    path: "/logistic-service/orders",
    create: {
      enabled: true,
      action: () => {
        navigate("/orders/create");
      },
      title: dictionary["create_new_order"][language],
      icon: faPlus,
    },
    sorting: [
      {
        orderBy: "desc",
        column: "creationDate",
        icon: faArrowDownShortWide,
        label: dictionary["creation_date"][language],
        isDefault: true,
      },
      {
        orderBy: "asc",
        column: "creationDate",
        icon: faArrowUpShortWide,
        label: dictionary["creation_date"][language],
        isDefault: false,
      },
    ],
    banner: {
      enabled: true,
    },
    title: dictionary["my_orders"][language],
    omnisearch: {
      enabled: true,
      placeholder: dictionary["references"][language],
    },
    defaultDate: {
      enabled: true,
      fieldName: "creationDate",
      label: dictionary["creation_date"][language],
      value: [
        moment().subtract(1, "months").startOf("day").toDate(),
        moment().endOf("day").toDate(),
      ],
    },
    actions: [
      // {
      //   onClick: (filters, onSearch) => {
      //     onSearch("ownerId", accessTokenPayload.owner, true);
      //   },
      //   label: `${dictionary["my_orders"][language]}`,
      //   icon: context?.orderLoader ? (
      //     <FontAwesomeIcon spin icon={faLoader} />
      //   ) : (
      //     <FontAwesomeIcon icon={faMagnifyingGlass} />
      //   ),
      //   className: "button-1 button-1-transparent",
      //   title: `${dictionary["filter_by"][language]} ${dictionary["my_orders"][
      //     language
      //   ].toLowerCase()}`,
      // },
      {
        onClick: (filters) => {
          if (filters?.["warehouse.id"]?.[0]) {
            context?.processNext(
              filters["warehouse.id"][0]?.id,
              listRef.current.refresh
            );
          }
        },
        label: `${dictionary["process"][language]} ${dictionary["next"][
          language
        ].toLowerCase()} ${dictionary["order"][language].toLowerCase()}`,
        icon: context?.orderLoader ? (
          <FontAwesomeIcon spin icon={faLoader} />
        ) : (
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        ),
        className: "button-1 button-1-transparent",
        hidden:
          accessTokenPayload.owner !== "7f8625b9-973b-4c66-80af-346b49cde2e7", //allabox senders
        loading: context?.orderLoader,
        title: "Selezionare filtro magazzino",
      },
    ],
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: `${dictionary["export"][language]} ${dictionary["for"][
              language
            ].toLowerCase()} ${dictionary["activation"][
              language
            ].toLowerCase()}`,
            action: "exportForActivation",
            data: selectedRows.map((item) => item.id),
          }),
        label: `${dictionary["export"][language]} ${dictionary["for"][
          language
        ].toLowerCase()} ${dictionary["activation"][language].toLowerCase()}`,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: `${dictionary["export"][language]} ${dictionary["for"][
              language
            ].toLowerCase()} ${dictionary["billing"][language].toLowerCase()}`,
            action: "exportForInvoicing",
            data: selectedRows.map((item) => item.id),
          }),
        label: `${dictionary["export"][language]} ${dictionary["for"][
          language
        ].toLowerCase()} ${dictionary["billing"][language].toLowerCase()}`,
        icon: <FontAwesomeIcon icon={faDownload} />,
        className: "button-1 button-1-transparent",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_order"][language],
            action: "confirm",
            data: selectedRows.map((item) => item.id),
          }),

        isDisabled: isDisabledConfirm,
        label: dictionary["confirm_selected"][language],
        icon: <FontAwesomeIcon icon={faCheck} />,
        className: "button-1 button-1-dark",
      },
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item),
          }),

        isDisabled: isDisabledDelete,
        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    filters: {
      name: "orders",
      preserve: false,
      list: [
        {
          fieldName: "lastStatus",
          label: dictionary["status"][language],
          type: "select",
          options: types.orderStatus,
          isMulti: true,
          optionStyle: getOrderStatusOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "ownerId",
          label: dictionary["customer"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllCompanies(
              searchValue,
              pagination,
              {
                orderBy: "asc",
                selector: "name",
              },
              [{ selector: "type", value: "CUSTOMER", preciseSearch: true }]
            );
          },
          isMulti: true,
          optionStyle: getCompanyOptionStyle,
          primary: true,
          col: 3,
        },
        {
          fieldName: "creationUser",
          label: dictionary["creation_user"][language],
          type: "text",
          preciseSearch: false,
          placeholder: "user@allabox.it",
          primary: true,
          col: 3,
        },
        {
          fieldName: "delivery.name",
          label: dictionary["receiver"][language],
          type: "text",
          preciseSearch: false,
          primary: true,
          col: 3,
        },
        {
          fieldName: "type",
          label: dictionary["type"][language],
          type: "select",
          options: types.orderTypes,
          isMulti: true,
          primary: false,
          col: 3,
        },
        {
          fieldName: "warehouse.id",
          label: dictionary["warehouse"][language],
          type: "async-select",
          loadOptions: (searchValue, pagination) => {
            return getAllWarehouses(searchValue, pagination, {
              orderBy: "asc",
              selector: "name",
            });
          },
          isMulti: false,
          optionStyle: getWarehouseOptionStyle,
          primary: false,
          col: 3,
        },
        {
          fieldName: "cashOnDeliveryValue",
          label: dictionary["cash_on_delivery"][language],
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
        {
          fieldName: "insuranceValue",
          label: dictionary["insurance"][language],
          type: "select",
          options: types.confirmationOptions,
          primary: false,
          col: 2,
        },
      ],
    },
  };
};

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import BreadcrumbItem from "../../Components/common/Breadcrumb";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useOidcUser } from "@axa-fr/react-oidc";
import { Link, useNavigate } from "react-router-dom";
import classnames from "classnames";
import { ToastContainer, toast } from "react-toastify";
import {
  carrierLogos,
  getAttachmentTypeLabel,
  getShipmentLabel,
  writeLabelAddress,
  useQuery,
} from "../../utilities";
import ParcelsList from "./parcelsList";
import { ShipmentsService as ShipmentsShipmentService } from "../../services/shipment-service/shipments";
import CompaniesService from "../../services/registry-service/companies";
import ShipmentEventsService from "../../services/shipment-service/shipmentEvents";
import ShipmentCostsService from "../../services/shipment-service/shipmentCosts";
import AttachmentsService from "../../services/registry-service/attachments";
import { CashOnDeliveryCollectionMethodsService } from "../../services/registry/cashOnDeliveryCollectionMethods";
import Delete from "./delete";
import Confirm from "./confirm";
import CreateEdit from "../Collections/CreateEdit";
import { defaultContact } from "./contact";
import CustomDataPresentation from "./presentation/customDataPresentation";
import { CustomDataService } from "../../services/shipment/customData";
import ZPLViewer from "../../Components/common/ZPLViewer";
import Event from "../Tracking/Event";

const shipmentsShipmentService = new ShipmentsShipmentService();
const companiesRegistryService = new CompaniesService();
const shipmentEventsService = new ShipmentEventsService();
const shipmentCostsService = new ShipmentCostsService();
const attachmentsService = new AttachmentsService();
const cashOnDeliveryCollectionMethodsService =
  new CashOnDeliveryCollectionMethodsService();
const customDataService = new CustomDataService();

const modalSettingsDefault = {
  open: false,
  title: "",
  icon: "",
  action: "",
};

const DetailsShipment = () => {
  const { id } = useParams();
  const query = useQuery();
  //acr = ask collection reservetion
  let acr = query.get("acr");
  const { oidcUser } = useOidcUser();
  const [trackingCollapse, setTrackingCollapse] = useState(false);
  const [costsAndOptionsCollapse, setCostsAndOptionsCollapse] = useState(false);
  const [modalSettings, setModalSettings] = useState({ modalSettingsDefault });

  const [shipment, setShipment] = useState({});
  const [genericFields, setGenericFields] = useState([]);
  const [shipmentEvents, setShipmentEvents] = useState([]);
  const [shipmentCosts, setShipmentCosts] = useState([]);
  const [shipmentOptions, setShipmentOptions] = useState([]);
  const [attachments, setAttachments] = useState([]);
  const [myCompanyGenericFields, setMyCompanyGenericFields] = useState([]);
  const [cashOnDeliveryCollectionMethod, setCashOnDeliveryCollectionMethod] =
    useState(null);
  const [customData, setCustomData] = useState([]);
  const navigate = useNavigate();

  const getShipment = () => {
    shipmentsShipmentService
      .getShipment(id)
      .then((response) => {
        setShipment(response);
        if (response.cashOnDeliveryCollectionMethodCode) {
          getCashOndeLiveryCollectionMethod(
            response.cashOnDeliveryCollectionMethodCode
          );
        }
        if (
          response.contractService?.carrierService?.carrier
            .collectionCreationEnabled &&
          !response.collectionAssigned &&
          acr === "true" &&
          (response.status.code === "BOOKED" ||
            response.status.code === "CONFIRMED")
        ) {
          setModalSettings({
            ...modalSettings,
            title: "Vuoi prenotare un ritiro?",
            icon: "ph-calendar-check",
            open: !modalSettings.open,
            id,
            action: "createCollection",
          });
        }
      })
      .catch((exception) => {
        toast.error(`Recupero spedizione fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(exception);
      });
  };

  const getCashOndeLiveryCollectionMethod = (code) => {
    cashOnDeliveryCollectionMethodsService
      .getCashOndeLiveryCollectionMethod(code)
      .then((response) => {
        if (response.status === 200) {
          setCashOnDeliveryCollectionMethod(response.data);
        }
      })
      .catch((err) => {
        let message = "";
        if (err.code === "ERR_NETWORK") {
          message = "Errore di rete, verifica la connessione.";
        }
        switch (err.response.status) {
          case 404:
            message = "Codice Metodo contrassegno non travato";
        }
        toast.error(message, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getCosts = () => {
    const filters = {
      search: {
        shipmentId: id,
      },
    };
    shipmentCostsService
      .getAll(filters)
      .then((response) => {
        setShipmentCosts(response.content || []);
      })
      .catch((exception) => {
        toast.error(`Recupero costi della spedizione fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(exception);
      });
  };
  const fetchCustomData = () => {
    const filters = {
      search: [
        {
          selector: "shipmentId",
          value: id,
          preciseSearch: true,
        },
      ],
    };
    customDataService
      .all(filters)
      .then((response) => {
        setCustomData(response.data.content || []);
      })
      .catch((exception) => {
        toast.error(`Recupero Dati doganali fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };
  const getAttachments = () => {
    const filters = {
      search: {
        shipmentId: id,
      },
    };
    attachmentsService
      .getAll(filters)
      .then((response) => {
        setAttachments(response.content || []);
      })
      .catch((exception) => {
        toast.error(`Recupero allegati fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(exception);
      });
  };

  const getOptions = () => {
    shipmentsShipmentService
      .getOptions(id)
      .then((response) => {
        setShipmentOptions(response || []);
      })
      .catch((exception) => {
        toast.error(`Recupero opzioni della spedizione fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getShipmentEvents = () => {
    const filters = {
      search: {
        shipmentId: id,
      },
      sort: {
        property: "eventDate",
        mode: "desc",
      },
    };
    shipmentEventsService
      .getAll(filters)
      .then((response) => {
        setShipmentEvents(response.content || []);
      })
      .catch((exception) => {
        toast.error(`Recupero eventi della spedizione fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
        console.log(exception);
      });
  };

  const getGenericFields = () => {
    shipmentsShipmentService
      .getGenericFields(id)
      .then((response) => {
        setGenericFields(response);
      })
      .catch((exception) => {
        toast.error(`Recupero campi aggiuntivi fallito`, {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const getMyCompanyGenericFields = () => {
    if (oidcUser) {
      companiesRegistryService
        .getGenericFields(oidcUser.owner)
        .then((response) => {
          setMyCompanyGenericFields(response || []);
        })
        .catch((exception) => {
          toast.error(`Recupero campi generici della company fallito`, {
            position: toast.POSITION.TOP_CENTER,
          });
        });
    }
  };

  const modalToggle = () => {
    setModalSettings({ modalSettingsDefault, open: !modalSettings.open });
  };

  useEffect(() => {
    getShipment();
    getGenericFields();
    getShipmentEvents();
    getCosts();
    getOptions();
    getAttachments();
    fetchCustomData();
  }, []);

  useEffect(() => {
    getMyCompanyGenericFields();
  }, [oidcUser]);

  return (
    <React.Fragment>
      {window.location.pathname === "/layout_no_header" ||
      window.location.pathname === "/layout_fixed_header" ? (
        ""
      ) : (
        <BreadcrumbItem
          title="Spedizioni"
          breadcrumbItem="Dettaglio spedizione"
        />
      )}
      <div className="content" id="container">
        <div className="row">
          <div className="col">
            <Card className="">
              <CardHeader>
                <div className="row align-items-center">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">Rif. interno</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>{shipment.internalReference}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">
                          Lettera di vettura
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label>{shipment.carrierReference || "-"}</label>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    {shipment.status && (
                      <div
                        style={{ backgroundColor: shipment.status.color }}
                        className={`status-container float-end`}
                      >
                        <span className={`status-name-small`}>
                          {shipment.status.name}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <div className="row align-items-center">
                  {shipment.contractService?.id && (
                    <div className="col-5 text-center">
                      <img
                        className="mb-2"
                        width={75}
                        src={
                          carrierLogos[
                            shipment?.contractService?.carrierService.carrier
                              .code
                          ]
                        }
                      />
                      <span className="fs-sm text-muted fw-regular d-block">
                        {shipment?.contractService?.carrierService.name || "-"}
                      </span>
                    </div>
                  )}

                  <div className="col-4">
                    <div className="row">
                      <div className="col">
                        <span className="fw-semibold">
                          {/* {shipment.parcelType &&
                            parcels &&
                            `${parcels.length}x ${shipment.parcelType.name}`} */}
                          Visualizza colli{" "}
                          <i
                            className="ph-magnifying-glass cursor-pointer"
                            onClick={() =>
                              setModalSettings({
                                ...modalSettings,
                                title: "Elenco dei colli",
                                icon: "ph-package",
                                open: !modalSettings.open,
                                action: "parcels",
                              })
                            }
                          />
                        </span>
                      </div>
                      <div className="row mt-2">
                        <div className="col">
                          <label className="fw-semibold d-inline">
                            Peso totale:
                          </label>{" "}
                          <label className="d-inline">
                            {shipment.weight && shipment.weight.toFixed(3)}kg
                          </label>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col">
                          <label className="fw-semibold d-inline">
                            Volume totale:
                          </label>{" "}
                          <label className="d-inline">
                            {(shipment.volume && shipment.volume.toFixed(6)) ||
                              "-"}
                            &#13221;
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3">
                    <div className="row">
                      <div className="col text-center">
                        {shipment?.ratingCost && (
                          <div className="badge bg-success fs-4">
                            <span className="fs-2 d-block">
                              {shipment.ratingCost.toFixed(2)} €
                            </span>
                          </div>
                        )}
                        {shipment?.ratingTime > 0 && (
                          <div className="badge bg-info bg-opacity-20 text-info fs-4 mt-2">
                            <i className="ph-clock fs-4"></i>
                            <span className="" style={{ fontSize: "14px" }}>
                              {" "}
                              {shipment.ratingTime} ore
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <label className="fw-semibold">Punto di presa:</label>{" "}
                    <label>
                      {shipment.pickup &&
                        `${shipment.pickup.name} -
                        ${writeLabelAddress(shipment.pickup.location)}`}
                    </label>
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col">
                    <label className="fw-semibold">Destinatario:</label>{" "}
                    <label>
                      {shipment.delivery &&
                        `${shipment.delivery.name} -
                        ${writeLabelAddress(shipment.delivery.location)}`}
                    </label>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col">
                    <h6>Informazioni generali</h6>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">
                          Tipologia spedizione:
                        </label>{" "}
                        <label>{getShipmentLabel(shipment.shipmentType)}</label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <label className="fw-semibold">
                          Tipologia etichetta:
                        </label>{" "}
                        <label>
                          {getAttachmentTypeLabel(shipment.labelTypeCode) ||
                            "-"}
                        </label>
                      </div>
                    </div>
                    {shipment.collection?.internalReference && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold d-block">
                            Rif. ritiro:
                          </label>{" "}
                          <Link
                            target="blank"
                            to={"../collections?id=" + shipment.collection.id}
                          >
                            {shipment.collection.internalReference}
                          </Link>
                        </div>
                      </div>
                    )}

                    {shipment.goodsValue && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">Valore merce:</label>{" "}
                          <label>
                            {shipment.goodsValue.toFixed(2)}{" "}
                            {shipment.goodsValueCurrency}
                          </label>
                        </div>
                      </div>
                    )}
                    {shipment.cashOnDeliveryValue && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">
                            Valore contrassegno:
                          </label>{" "}
                          <label>
                            {shipment.cashOnDeliveryValue.toFixed(2)}{" "}
                            {shipment.cashOnDeliveryValueCurrency}
                          </label>
                        </div>
                      </div>
                    )}
                    {shipment.cashOnDeliveryValue &&
                      cashOnDeliveryCollectionMethod && (
                        <div className="row">
                          <div className="col">
                            <label className="fw-semibold">
                              Metodo incasso contrassegno:
                            </label>{" "}
                            <label>{cashOnDeliveryCollectionMethod.name}</label>
                          </div>
                        </div>
                      )}
                    {shipment.insuranceValue && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">
                            Valore assicurazione:
                          </label>{" "}
                          <label>
                            {shipment.insuranceValue.toFixed(2)}{" "}
                            {shipment.insuranceValueCurrency}
                          </label>
                        </div>
                      </div>
                    )}
                    {shipment.ddtNumber && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">DDT Number:</label>{" "}
                          <label>{shipment.ddtNumber}</label>
                        </div>
                      </div>
                    )}
                    {customData?.length > 0 && (
                      <CustomDataPresentation customData={customData} />
                    )}
                  </div>
                  <div className="col">
                    <h6>Campi aggiuntivi</h6>
                    {/* {genericFields.length > 0 &&
                      genericFields.map((elem, index) => {
                        var labelCounter = 0;
                        return (
                          <div className="row" key={index}>
                            <div className="col">
                              {myCompanyGenericFields &&
                                myCompanyGenericFields.map((elem2, index2) => {
                                  if (elem2.rank == elem.rank) {
                                    labelCounter = 1;
                                    return (
                                      <label
                                        className="fw-semibold"
                                        key={index2}
                                      >
                                        {elem2.value}:
                                      </label>
                                    );
                                  }
                                  return "";
                                })}
                              {labelCounter === 0 && (
                                <label className="fw-semibold">
                                  {`Campo aggiuntivo ${index + 1}`}:
                                </label>
                              )}{" "}
                              <label>{elem.value || "-"}</label>
                            </div>
                          </div>
                        );
                      })} */}
                    {shipment.externalReference && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">
                            Riferimento esterno:
                          </label>{" "}
                          <label>{shipment.externalReference}</label>
                        </div>
                      </div>
                    )}
                    {shipment.content && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">Contenuto:</label>{" "}
                          <label>{shipment.content}</label>
                        </div>
                      </div>
                    )}
                    {shipment.note && (
                      <div className="row">
                        <div className="col">
                          <label className="fw-semibold">Note:</label>{" "}
                          <label>{shipment.note}</label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {shipment && (
                  <React.Fragment>
                    <div
                      className="row mt-3 accordion"
                      id="costsAndOptionsAccordion"
                    >
                      <div className="col text-center">
                        <Link
                          to="#"
                          color="primary"
                          type="button"
                          onClick={() =>
                            setCostsAndOptionsCollapse(!costsAndOptionsCollapse)
                          }
                          style={{ cursor: "pointer", fontSize: "1rem" }}
                          className="mb-2"
                        >
                          Visualizza costi e opzioni
                        </Link>
                      </div>

                      <Collapse
                        isOpen={costsAndOptionsCollapse}
                        className="accordion-collapse"
                      >
                        <div className="row justify-content-center mt-4">
                          <div className="col">
                            <h6>Costi</h6>
                            {shipmentCosts.length > 0 ? (
                              shipmentCosts.map((elem, index) => {
                                let valueLabel;
                                if (elem.value === 0) {
                                  valueLabel = (
                                    <label className="text-success">
                                      Gratuito
                                    </label>
                                  );
                                } else if (elem.value === null) {
                                  valueLabel = (
                                    <label className="text-danger">
                                      Non disponibile
                                    </label>
                                  );
                                } else {
                                  let time =
                                    elem.time > 0 ? `/ ${elem.time} ore` : "";
                                  valueLabel = (
                                    <label>
                                      {elem.value.toFixed(2)}€ {time}
                                    </label>
                                  );
                                }
                                return (
                                  <div key={index} className="row">
                                    <div className="col">
                                      <label className="fw-semibold">
                                        {elem.name}:{" "}
                                      </label>{" "}
                                      {""}
                                      {valueLabel}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <label>Nessun costo da mostrare</label>
                            )}
                          </div>
                          <div className="col">
                            <h6>Opzioni</h6>
                            {shipmentOptions.length > 0 ? (
                              shipmentOptions.map((elem, index) => {
                                return (
                                  <div key={index} className="row">
                                    <div className="col">
                                      <label className="fw-semibold">
                                        {elem.surcharge.name}
                                      </label>
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <label>Nessuna opzione da mostrare</label>
                            )}
                          </div>
                        </div>
                      </Collapse>
                    </div>
                    <div className="row">
                      <div className="col"></div>
                    </div>
                  </React.Fragment>
                )}
              </CardBody>
            </Card>
          </div>
          <div className="col">
            <Card>
              <div className="accordion" id="accordion">
                <h5 className="accordion-header" id="headingOne">
                  <Link
                    to="#"
                    color="primary"
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !trackingCollapse,
                    })}
                    type="button"
                    onClick={() => setTrackingCollapse(!trackingCollapse)}
                    style={{ cursor: "pointer", fontSize: "1.125rem" }}
                  >
                    Tracking
                  </Link>
                </h5>
                <Collapse
                  isOpen={trackingCollapse}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="row justify-content-center">
                      <div className="col">
                        {shipmentEvents.map((elem, i) => {
                          return (
                            <Event
                              key={i}
                              data={elem}
                              formatDate={"DD/MM/YYYY HH:mm"}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </Card>
            {attachments.length > 0 && (
              <div className="col">
                <div className="row">
                  <div className="col">
                    <Card>
                      <CardHeader>
                        <div className="row">
                          <div className="col">
                            <h5 className="m-0">Etichette e documenti</h5>
                          </div>
                          <div className="col text-end">
                            {shipment?.orderId && (
                              <Link
                                color="light"
                                className="btn btn-outline-success btn-sm"
                                to={"/shipments/ddt/" + id}
                                target="blank"
                              >
                                Stampa DDT <i className="ph-download ms-2"></i>
                              </Link>
                            )}
                          </div>
                        </div>
                      </CardHeader>
                      <CardBody>
                        <div className="row">
                          {attachments.map((elem, index) => {
                            return (
                              <div
                                key={index}
                                className={`${
                                  attachments.length === 1 ? "col" : "col-6"
                                } mt-3`}
                              >
                                <iframe
                                  className="w-100"
                                  height={`${
                                    attachments.length === 1 ? 300 : null
                                  }`}
                                  src={elem.url}
                                ></iframe>
                                <div className="row mt-3">
                                  <div className="col text-center">
                                    <Link
                                      color="light"
                                      className="btn btn-outline-success btn-sm"
                                      to={elem.url}
                                      target="blank"
                                    >
                                      DOWNLOAD{" "}
                                      <i className="ph-download ms-2"></i>
                                    </Link>
                                    {elem.type.format === "ZPL" && (
                                      <button
                                        type="button"
                                        className="btn btn-light btn-sm ms-2"
                                        title="Etichetta ZPL in PDF"
                                        onClick={() =>
                                          setModalSettings({
                                            ...modalSettings,
                                            title: "Etichetta ZPL in PDF",
                                            icon: "ph-file-pdf",
                                            open: !modalSettings.open,
                                            url: elem.url,
                                            action: "zpl",
                                          })
                                        }
                                      >
                                        <i className="ph-file-pdf"></i>
                                      </button>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            )}
          </div>

          {/* <div className={`${attachments.length > 0 ? "col-12 mt-3" : "col"}`}>
            <Card>
              <div className="accordion" id="accordion">
                <h5 className="accordion-header" id="headingOne">
                  <Link
                    to="#"
                    color="primary"
                    className={classnames("accordion-button", "fw-medium", {
                      collapsed: !trackingCollapse,
                    })}
                    type="button"
                    onClick={() => setTrackingCollapse(!trackingCollapse)}
                    style={{ cursor: "pointer", fontSize: "1.125rem" }}
                  >
                    Tracking
                  </Link>
                </h5>
                <Collapse
                  isOpen={trackingCollapse}
                  className="accordion-collapse"
                >
                  <div className="accordion-body">
                    <div className="row justify-content-center">
                      <div className="col-8">
                        <Tracking data={shipmentEvents} />
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </Card>
          </div> */}
        </div>

        <div className="row mt-3">
          <div className="col text-start">
            <button
              type="button"
              className="btn btn-light btn-sm"
              onClick={() => {
                navigate(`/shipments`);
              }}
            >
              <i className="ph-arrow-left ph-sm me-2"></i>Torna all'elenco
              spedizioni
            </button>
          </div>
          <div className="col text-end">
            <button
              type="button"
              className="btn btn-outline-primary btn-sm me-2"
              onClick={() => {
                navigate(`/shipments/clone/${id}`);
              }}
            >
              <i className="ph-users ph-sm me-2"></i>Duplica
            </button>
            {(shipment.status?.code === "BOOKED" ||
              shipment.status?.code === "DRAFT") && (
              <button
                type="button"
                className="btn btn-outline-danger btn-sm me-2"
                onClick={() =>
                  setModalSettings({
                    ...modalSettings,
                    title: "Elimina",
                    icon: "ph-trash",
                    open: !modalSettings.open,
                    id,
                    action: "delete",
                  })
                }
              >
                <i className="ph-trash ph-sm me-2"></i>Elimina
              </button>
            )}
            {shipment.status?.code === "DRAFT" && (
              <React.Fragment>
                <button
                  type="button"
                  className="btn btn-outline-primary btn-sm me-2"
                  onClick={() => {
                    navigate(`/shipments/edit/${id}`);
                  }}
                >
                  <i className="ph-note-pencil ph-sm me-2"></i>Modifica
                </button>
                <button
                  type="button"
                  className="btn btn-outline-success btn-sm"
                  onClick={() => {
                    navigate(`/shipments/rate/${id}`);
                  }}
                >
                  <i className="ph-note-pencil ph-sm me-2"></i>Scegli tariffa
                </button>
              </React.Fragment>
            )}

            {shipment.status?.code === "BOOKED" && (
              <button
                type="button"
                className="btn btn-light btn-sm me-2"
                onClick={() =>
                  setModalSettings({
                    ...modalSettings,
                    title: "Conferma",
                    icon: "ph-check",
                    open: !modalSettings.open,
                    id: id,
                    action: "confirm",
                  })
                }
              >
                <i className="ph-check ph-sm me-2"></i>Conferma
              </button>
            )}
            {(shipment.status?.code === "BOOKED" ||
              shipment.status?.code === "CONFIRMED") &&
              !shipment.collectionAssigned &&
              shipment.contractService.carrierService.carrier
                .collectionCreationEnabled && (
                <button
                  type="button"
                  className="btn btn-light btn-sm me-2"
                  onClick={() => {
                    setModalSettings({
                      ...modalSettings,
                      title: "Vuoi prenotare un ritiro?",
                      icon: "ph-calendar-check",
                      open: !modalSettings.open,
                      id,
                      action: "createCollection",
                    });
                  }}
                >
                  <i className="ph-calendar-check ph-sm me-2"></i>
                  Vuoi prenotare un ritiro?
                </button>
              )}
          </div>
        </div>
      </div>

      <Modal isOpen={modalSettings.open} className="modal-content modal-lg">
        <ModalHeader toggle={modalToggle}>
          <span className="modal-title">
            <i className={modalSettings.icon + " me-2"}></i>
            {modalSettings.title}
          </span>
        </ModalHeader>
        <ModalBody>
          {modalSettings.action === "parcels" && (
            <ParcelsList shipmentId={shipment.id} />
          )}

          {modalSettings.action === "delete" && (
            <Delete
              id={id}
              callback={(success) => {
                if (success) {
                  navigate(`/shipments`);
                  return false;
                }
                modalToggle();
              }}
            />
          )}
          {modalSettings.action == "confirm" && (
            <Confirm
              id={modalSettings.id}
              callback={(success) => {
                if (success) {
                  navigate(`/shipments`);
                  return false;
                }
                modalToggle();
              }}
            />
          )}
          {modalSettings.action == "createCollection" && (
            <CreateEdit
              userOwnerId={oidcUser?.owner}
              defaultContactId={shipment.pickup.id}
              shipmentIds={[shipment.id]}
              carrierCode={shipment.contractService.carrierService.carrier.code}
              unmountCallback={() => {
                acr = "false";
                getShipment();
                modalToggle();
              }}
            />
          )}
          {modalSettings.action == "zpl" && (
            <ZPLViewer
              url={modalSettings.url}
              callback={() => {
                modalToggle();
              }}
            />
          )}
          {/* {modalSettings.action === "customsData" && (
            <ParcelsList data={shipment.customsData} />
          )} */}
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default DetailsShipment;

import React, { createContext, useContext, useEffect, useState } from "react";

import { OrdersService } from "../../../services/logistic/orders";
import { callErrorToast } from "../../../utilities";

const ProcessOrderContext = createContext();

const ordersService = new OrdersService();

const ProcessOrdersProvider = ({ children, data, callback }) => {
  const [orders, setOrders] = useState(data);
  const [ordersError, setOrdersError] = useState(null);
  const [ordersLoader, setOrdersLoader] = useState(false);

  const processOrders = () => {
    setOrdersLoader(true);
    const receivePromises = orders.map((elem) => {
      return ordersService.receive(elem);
    });
    Promise.all(receivePromises)
      .then(() => {
        const processPromises = orders.map((elem) => {
          return ordersService.process(elem);
        });

        Promise.all(processPromises)
          .then(() => {
            setOrdersLoader(false);
            if (callback) {
              callback(true);
            }
          })
          .catch((err) => {
            setOrdersLoader(false);
            setOrdersError(err);
          });
      })
      .catch((err) => {
        setOrdersLoader(false);
        setOrdersError(err);
      });
  };

  useEffect(() => {
    if (ordersError) {
      callErrorToast(ordersError);
    }
  }, [ordersError]);

  return (
    <ProcessOrderContext.Provider
      value={{
        orders,
        ordersError,
        ordersLoader,
        processOrders,

        callback,
      }}
    >
      {children}
    </ProcessOrderContext.Provider>
  );
};

const useProcessOrderContext = () => {
  return useContext(ProcessOrderContext);
};

export { ProcessOrdersProvider, useProcessOrderContext };

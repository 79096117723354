import React, { useRef } from "react";
import "../../assets/scss/pages/ddt.scss";
import moment from "moment";
import { List } from "../../Components/common/List/List";
import { ListProvider } from "../../Components/common/List/Providers/List";
import { dictionary } from "../../utilities/dictionary";
import { useTypes } from "../../utilities/types";
import { useAppContext } from "../../AppProvider";
import { useListProps } from "./utilities/list";
import { ButtonConfirm } from "../../Components/common/Buttons/ButtonConfirm";
import LogoDark from "../../assets/images/logo-black.svg";
import { useReactToPrint } from "react-to-print";
import { useDDTContext } from "./providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLoader } from "@awesome.me/kit-c1b85ff10f/icons/classic/light";

const DDTShipment = () => {
  const appContext = useAppContext();
  const listProps = useListProps();
  const types = useTypes();

  const listRef = useRef(null);
  const contentRef = useRef(null);

  const language = appContext?.language;

  const context = useDDTContext();

  const renderHeader = () => {
    return (
      <div
        className=" d-grid grid-column align-items-center"
        style={{
          gridTemplateColumns: `0.5fr 1.5fr 0.75fr 0.75fr 0.75fr 0.75fr 0.75fr 0.25fr 0.75fr`,
        }}
      >
        <div></div>
        <div>
          <label className="label-2">{dictionary["product"][language]}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["description"][language]}
          </label>
        </div>
        <div>
          <label className="label-2">{dictionary["quantity"][language]}</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["unit_price"][language]} <br /> (IVA{" "}
            {dictionary["excluded_fem"][language]})
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["discount"][language]} <br /> (IVA{" "}
            {dictionary["excluded_fem"][language]})
          </label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["total"][language]} <br /> (IVA{" "}
            {dictionary["excluded_fem"][language]})
          </label>
        </div>
        <div>
          <label className="label-2">IVA</label>
        </div>
        <div>
          <label className="label-2">
            {dictionary["total"][language]} <br /> (IVA{" "}
            {dictionary["included_fem"][language]})
          </label>
        </div>
      </div>
    );
  };

  const renderItem = (item, key) => {
    return <ListItem item={item} index={key} />;
  };

  const renderFooter = (data) => {
    // context?.setShipment({
    //   ...context?.shipment,
    //   shippingRequiredOrderDetails: data?.content.reduce(
    //     (acc, od) => acc + (od.article.shippingRequired === true ? 1 : 0),
    //     0
    //   ),
    // });
    const currency = types.currencies.find(
      (x) => x.value === data.content[0].order.currency
    ).label;

    return (
      <div
        className=" d-grid grid-column align-items-center mt-2"
        style={{
          gridTemplateColumns: `0.5fr 1.5fr 0.75fr 0.75fr 0.75fr 0.75fr 0.75fr 0.25fr 0.75fr`,
        }}
      >
        <div>
          <label className="label-3">{dictionary["total"][language]}</label>
        </div>
        <div></div>
        <div></div>
        <div></div>
        <div>
          <label className="label-3">
            {data.content
              .reduce((acc, obj) => acc + (obj.unitPrice || 0), 0)
              .toFixed(2)}{" "}
            {currency}
          </label>
        </div>
        <div>
          <label className="label-3">
            {data.content
              .reduce((acc, obj) => acc + (obj.discount || 0), 0)
              .toFixed(2)}{" "}
            {currency}
          </label>
        </div>
        <div>
          <label className="label-3">
            {data.content
              .reduce((acc, obj) => acc + (obj.totalPrice || 0), 0)
              .toFixed(2)}{" "}
            {currency}
          </label>
        </div>
        <div></div>
        <div>
          <label className="label-3">
            {data.content
              .reduce((acc, obj) => acc + (obj.totalPrice || 0) * 1.22, 0)
              .toFixed(2)}{" "}
            {currency}
          </label>
        </div>
      </div>
    );
  };

  const printFn = useReactToPrint({
    contentRef: contentRef,
    documentTitle: "DDT Shipment",
  });

  const handleOnClick = React.useCallback(() => {
    printFn();
  }, [printFn]);

  document.body.classList.add("overflow-auto");

  return (
    <div className="ddt" ref={contentRef}>
      <div className="ddt-header">
        <div className="cover">
          <img src={context?.shipment?.owner.logoDarkUrl} />
          <ButtonConfirm icon={false} onClick={handleOnClick}>
            {dictionary["print"][language]}
          </ButtonConfirm>
        </div>
      </div>

      <div className="ddt-content">
        {context?.shipmentLoader ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faLoader} spin size="2x" />
          </div>
        ) : (
          <>
            <h2 className="fw-bold">
              {dictionary["transport_document"][language]} #
              {context?.shipment?.internalReference}
            </h2>
            <div className="row">
              <div className="col">
                <div className="box-container border border-light h-100">
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["creation_date"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {moment(context?.shipment?.creationDate).format(
                        "DD/MM/YYYY HH:mm:ss"
                      )}
                    </label>
                  </div>
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["number"][language]}{" "}
                      {dictionary["of"][language]}{" "}
                      {dictionary["parcels"][language].toLowerCase()}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.parcels}
                    </label>
                  </div>
                  {/* <div>
                    <label className="label-2 d-inline">
                      {dictionary["total_number"][language]}{" "}
                      {dictionary["of"][language]}{" "}
                      {dictionary["physical_goods"][language].toLowerCase()}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.shippingRequiredOrderDetails || "-"}
                    </label>
                  </div> */}
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["weight"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.weight.toFixed(2)} kg
                    </label>{" "}
                    <label className="label-2 d-inline">
                      {dictionary["volume"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.volume.toFixed(3)} kg
                    </label>
                  </div>
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["causal"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {dictionary["sale"][language]}
                    </label>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="box-container border border-light h-100">
                  <h3 className="mb-1">{dictionary["carrier"][language]}</h3>
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["name"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.contractService?.contract?.carrier
                        ?.name || "-"}
                    </label>
                  </div>
                  {context?.shipment?.carrierReference && (
                    <div>
                      <label className="label-2 d-inline">
                        {dictionary["reference"][language]}:
                      </label>{" "}
                      <label className="label-3 d-inline">
                        {context?.shipment?.carrierReference}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <div className="col">
                <div className="box-container border border-light h-100">
                  <h3 className="mb-1">{dictionary["sender"][language]}</h3>
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["company_name"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.owner?.name}
                    </label>
                  </div>
                  {context?.shipment?.owner?.externalReference && (
                    <div>
                      <label className="label-2 d-inline">
                        {dictionary["reference"][language]}:
                      </label>{" "}
                      <label className="label-3 d-inline">
                        {context?.shipment?.owner?.externalReference}
                      </label>
                    </div>
                  )}
                </div>
              </div>
              <div className="col">
                <div className="box-container border border-light h-100">
                  <h3 className="mb-1">{dictionary["receiver"][language]}</h3>
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["company_name"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.delivery?.name}
                    </label>
                  </div>
                  {context?.shipment?.delivery?.externalReference && (
                    <div>
                      <label className="label-2 d-inline">
                        {dictionary["reference"][language]}:
                      </label>{" "}
                      <label className="label-3 d-inline">
                        {context?.shipment?.delivery?.externalReference}
                      </label>
                    </div>
                  )}
                  <div>
                    <label className="label-2 d-inline">
                      {dictionary["address"][language]}:
                    </label>{" "}
                    <label className="label-3 d-inline">
                      {context?.shipment?.delivery?.location?.label || "-"}
                    </label>
                  </div>
                  {context?.shipment?.delivery?.vatNumber && (
                    <div>
                      <label className="label-2 d-inline">
                        {dictionary["vat_number"][language]}:
                      </label>{" "}
                      <label className="label-3 d-inline">
                        {context?.shipment?.delivery?.vatNumber}
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            {context?.shipment?.orderId && (
              <div className="mt-2">
                <ListProvider
                  path={listProps.path}
                  defaultPagination={listProps.pagination}
                  parentId={context?.shipment?.orderId}
                >
                  <List
                    ref={listRef}
                    title={listProps.title}
                    pagination={listProps.pagination}
                    selectRowsEnabled={listProps.selectRowsEnabled}
                    renderHeader={renderHeader}
                    renderItem={renderItem}
                    renderFooter={renderFooter}
                    actionBar={listProps.actionBar}
                  />
                </ListProvider>
              </div>
            )}
            <div className="row mt-4">
              <div className="col text-center">
                <label className="label-2 d-inline">
                  {dictionary["signature"][language]}{" "}
                  {dictionary["sender"][language]}:
                </label>
                <hr className="m-0 m-auto mt-4 w-75"></hr>
              </div>
              <div className="col text-center">
                <label className="label-2 d-inline">
                  {dictionary["signature"][language]}{" "}
                  {dictionary["carrier"][language]}:
                </label>
                <hr className="m-0 m-auto mt-4 w-75"></hr>
              </div>
              <div className="col text-center">
                <label className="label-2 d-inline">
                  {dictionary["signature"][language]}{" "}
                  {dictionary["receiver"][language]}:
                </label>
                <hr className="m-0 m-auto mt-4 w-75"></hr>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="ddt-footer">
        <img src={LogoDark}></img>
      </div>
    </div>
  );
};

export default DDTShipment;

const ListItem = React.memo(({ item, index }) => {
  const types = useTypes();
  const currency = types.currencies.find(
    (x) => x.value === item.order.currency
  ).label;

  return (
    <React.Fragment key={index}>
      <div
        className="ddt-row d-grid grid-column align-items-center bg-color-3 border border-light mt-1"
        style={{
          gridTemplateColumns: `0.5fr 1.5fr 0.75fr 0.75fr 0.75fr 0.75fr 0.75fr 0.25fr 0.75fr`,
        }}
      >
        <div></div>
        <div>
          <label className="label-5">{item.article.name}</label>
        </div>
        <div>
          <label className="label-5">{item.barcode}</label>
        </div>
        <div>
          <label className="label-5">{item.quantity}</label>
        </div>
        <div>
          <label className="label-5">
            {item.unitPrice.toFixed(2)} {currency}
          </label>
        </div>
        <div>
          <label className="label-5">
            {item.discount && item.discount?.toFixed(2) + " " + currency}
          </label>
        </div>
        <div>
          <label className="label-5">
            {item.totalPrice.toFixed(2)} {currency}
          </label>
        </div>
        <div>
          <label className="label-5">22%</label>
        </div>
        <div>
          <label className="label-5">
            {((item.totalPrice || 0) * 1.22).toFixed(2)} {currency}
          </label>
        </div>
      </div>
    </React.Fragment>
  );
});

import {
  faChevronDoubleRight,
  faLoader,
  faPlus,
  faTrash,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { useAppContext } from "../../../AppProvider";
import { dictionary } from "../../../utilities/dictionary";
import { useIndexOrderDetailsContext } from "../providers/Index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHandlerOrderContext } from "../../Orders/providers/Handler";
import { useOidcAccessToken } from "@axa-fr/react-oidc";

export const useListProps = (action) => {
  const { accessTokenPayload } = useOidcAccessToken();

  const appContext = useAppContext();
  const language = appContext?.language;

  const context = useIndexOrderDetailsContext();
  const handlerOrderContext = useHandlerOrderContext();

  return {
    path: `/logistic-service/order-details`,
    banner: {
      enabled: false,
    },
    pagination: { enabled: false },
    defaultPagination: {
      page: 0,
      size: 200,
    },
    title: <h3 className="m-0">{dictionary["products"][language]}</h3>,
    create: {
      enabled: action !== "details",
      action: () => {
        context.setModal({
          open: true,
          title: `${dictionary["add"][language]} ${dictionary["product"][
            language
          ].toLowerCase()}`,
          action: "create",
        });
      },
      title: `${dictionary["add"][language]} ${dictionary["product"][
        language
      ].toLowerCase()}`,
      icon: faPlus,
    },
    selectRowsEnabled: { enabled: action !== "details" },
    actionBar: { enabled: action !== "details", fixed: action !== "details" },
    massiveActions: [
      {
        onClick: (selectedRows) =>
          context.setModal({
            open: true,
            title: dictionary["confirm_deletion"][language],
            action: "delete",
            data: selectedRows.map((item) => item.id),
          }),

        label: dictionary["delete_selected"][language],
        icon: <FontAwesomeIcon icon={faTrash} />,
        className: "button-1 button-1-danger",
      },
    ],
    actions: [
      {
        onClick: context?.nextDetail,
        label: `${dictionary["complete"][language]} ${dictionary["product"][
          language
        ].toLowerCase()}`,
        icon: context?.orderDetailLoader ? (
          <FontAwesomeIcon spin icon={faLoader} />
        ) : (
          <FontAwesomeIcon icon={faChevronDoubleRight} />
        ),
        className: "button-1 button-1-transparent",
        hidden:
          handlerOrderContext?.createShipmentEnabled ||
          (handlerOrderContext?.order?.lastStatus !== "PROCESSING" &&
            accessTokenPayload !== "7f8625b9-973b-4c66-80af-346b49cde2e7"),
        loading: context?.orderDetailLoader,
      },
    ],
  };
};

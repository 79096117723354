export let dictionary = {
  welcome_back: { en: "Welcome back", it: "Bentornato" },
  overview: { en: "Overview", it: "Panoramica" },
  show: { en: "Show", it: "Mostra" },
  see_all: { en: "See all", it: "Vedi tutti" },
  pages: { en: "Pages", it: "Pagine" },
  page: { en: "Page", it: "Pagina" },
  items: { en: "Items", it: "Elementi" },
  item: { en: "Item", it: "Elemento" },
  selected_items: { en: "Selected items", it: "Elementi selezionati" },
  filter_by: { en: "Filter by", it: "Filtra per" },
  filters: { en: "Filters", it: "Filtri" },
  sort_by: { en: "Sort by", it: "Ordina per" },
  select_all: { en: "Select all", it: "Seleziona tutti" },
  select: { en: "Select", it: "Seleziona" },
  no_data_show: { en: "No data to show", it: "Nessun dato da mostrare" },
  clear: { en: "Clear", it: "Pulisci" },
  export: { en: "Export", it: "Esporta" },
  today: { en: "Today", it: "Oggi" },
  yesterday: { en: "Yesterday", it: "Ieri" },
  current_month: { en: "Current month", it: "Mese corrente" },
  current_quarter: { en: "Current quarter", it: "Quarter corrente" },
  last_month: { en: "Last month", it: "Ultimi 30 giorni" },
  last_bimonthly: { en: "Last bimonthly", it: "Ultimi 60 giorni" },
  last_30_days: { en: "Last 30 days", it: "Ultimi 30 giorni" },
  previous_month: { en: "Previous month", it: "Mese precedente" },
  from_the: { en: "From the", it: "Dal" },
  all: { en: "All", it: "Tutti" },
  yes: { en: "Yes", it: "Si" },
  no: { en: "No", it: "No" },
  copy: { en: "Copy", it: "Copia" },
  copied: { en: "Copied", it: "Copiato" },
  back: { en: "Back", it: "Indietro" },
  add: { en: "Add", it: "Aggiungi" },
  for: { en: "For", it: "Per" },
  in: { en: "in", it: "in" },
  of: { en: "of", it: "di" },
  communicates: { en: "Communicatates", it: "Comunica" },
  change: { en: "Change", it: "Variazione" },
  greater_of: { en: "The greater of", it: "Il maggiore tra" },
  and: { en: "and", it: "e" },
  hours: { en: "Hours", it: "Ore" },
  free: { en: "Free", it: "Gratuito" },
  not_available: { en: "Not available", it: "Non disponibile" },
  availables: { en: "Availables", it: "Disponibili" },
  lost: { en: "Lost", it: "Smarriti" },
  surrender_time: { en: "Surrender time", it: "Tempo di resa" },
  verify: { en: "Verify", it: "Verifica" },
  process: { en: "Process", it: "Lavora" },
  next: { en: "Next", it: "Prossimo" },
  not_any: { en: "No", it: "Nessun" },
  to: { en: "to", it: "da" },
  included: { en: "included", it: "incluso" },
  included_fem: { en: "included", it: "inclusa" },
  excluded: { en: "excluded", it: "escluso" },
  excluded_fem: { en: "excluded", it: "esclusa" },
  search_address_book: {
    en: "Search in address book",
    it: "Cerca in rubrica",
  },
  type_to_enter_new_address: {
    en: "Type to enter a new address",
    it: "Digitare per inserire un nuovo indirizzo",
  },
  search: { en: "Search", it: "Cerca" },
  not_found: { en: "Not found", it: "Non trovato" },
  complete: { en: "Complete", it: "Completa" },
  total: { en: "Total", it: "Totale" },
  close: { en: "Close", it: "Chiudi" },

  references: { en: "References", it: "Riferimenti" },
  reference: { en: "Reference", it: "Riferimento" },
  status: { en: "Status", it: "Stato" },
  carrier: { en: "Carrier", it: "Vettore" },
  customer: { en: "Customer", it: "Cliente" },
  creation_date: { en: "Creation date", it: "Data di creazione" },
  service: { en: "Service", it: "Servizio" },
  internal_reference: { en: "Internal ref.", it: "Rif. Interno" },
  external_reference: { en: "External ref.", it: "Rif. Esterno" },
  customer_reference: { en: "Customer ref.", it: "Rif. Cliente" },
  sender_reference: { en: "Sender ref.", it: "Rif. Mittente" },
  carrier_reference: { en: "Carrier ref.", it: "Rif. Vettore" },
  logistic_reference: { en: "Logistic ref.", it: "Rif. Logistica" },
  store_reference: { en: "Store ref.", it: "Rif. Store" },
  allabox_reference: { en: "Allabox ref.", it: "Rif. Allabox" },
  package: { en: "Package", it: "Pacco" },
  parcels: { en: "Parcels", it: "Colli" },
  envelope: { en: "Envelope", it: "Busta" },
  document: { en: "Document", it: "Documento" },
  pallet: { en: "Pallet", it: "Pallet" },
  sender: { en: "Sender", it: "Mittente" },
  address_book: { en: "Adress book", it: "Rubrica" },
  receiver: { en: "Receiver", it: "Destinatario" },
  recipient_address: {
    en: "Recipient destinatario",
    it: "Indirizzo destinatario",
  },
  cash_on_delivery: { en: "Cash on delivery", it: "Contrassegno" },
  cod_collected: {
    en: "Cash on delivery collected",
    it: "Contrassegno incassato",
  },
  value: { en: "Value", it: "Valore" },
  insurance: { en: "Insurance", it: "Assicurazione" },
  collection: { en: "Collection", it: "Ritiro" },
  tracking: { en: "Tracking", it: "Tracking" },
  print: { en: "Print", it: "Stampa" },
  print_label: { en: "Print label", it: "Stampa etichetta" },
  print_labels: { en: "Print labels", it: "Stampa etichette" },
  print_manifest: { en: "Print manifest", it: "Stampa manifest" },
  book_collection: { en: "Book collection", it: "Prenota ritiro" },
  cod_method: {
    en: "COD Collection method",
    it: "Metodo incasso contrassegno",
  },
  creation_user: { en: "Creation user", it: "Utente di creazione" },
  collected_date: { en: "Collected date", it: "Data di incasso" },
  liquidated_date: { en: "Liquidated date", it: "Data di liquidazione" },
  delivery_date: { en: "Delivery date", it: "Data di consegna" },
  expected_amount: { en: "Expected amount", it: "Importo previsto" },
  collected_amount: { en: "Collected amount", it: "Importo incassato" },
  delta: { en: "Delta", it: "Delta" },
  note: { en: "Note", it: "Note" },
  day: { en: "Day", it: "Giorno" },
  supplier: { en: "Supplier", it: "Fornitore" },
  type: { en: "Type", it: "Tipologia" },
  description: { en: "Description", it: "Descrizione" },
  downloads: { en: "Downloads", it: "Downloads" },
  weight: { en: "Weight", it: "Peso" },
  total_weight: { en: "Total weight", it: "Peso totale" },
  total_price: { en: "Total price", it: "Prezzo totale" },
  volume: { en: "Volume", it: "Volume" },
  signature_for_acceptance: {
    en: "Signature for acceptance",
    it: "Firma per accettazione",
  },
  signature: {
    en: "Signature",
    it: "Firma",
  },
  forward: {
    en: "Forward",
    it: "Andata",
  },
  cash: {
    en: "Cash",
    it: "Contante",
  },
  general_infos: {
    en: "General informations",
    it: "Informazioni generali",
  },
  additional_infos: {
    en: "  Additional Information",
    it: "Informazioni aggiuntive",
  },
  shipment_infos: {
    en: "  Shipment Information",
    it: "Informazioni per la spedizione",
  },
  billing_infos: {
    en: "  Billing Information",
    it: "Informazioni per la fatturazione",
  },
  usd: {
    en: "US Dollar",
    it: "Dollaro statunitense",
  },
  eur: {
    en: "Euro",
    it: "Euro",
  },
  gbr: {
    en: "British pound sterling",
    it: "Sterlina britannica",
  },
  vat_regime: {
    en: "VAT regime",
    it: "Regime IVA",
  },
  vat_number: {
    en: "Vat number",
    it: "Partita IVA",
  },
  fiscal_code: {
    en: "Fiscal code",
    it: "Codice fiscale",
  },
  payment_method: {
    en: "Payment method",
    it: "Metodo di pagamento",
  },
  currency: {
    en: "Currency",
    it: "Valuta",
  },
  name: {
    en: "Name",
    it: "Nome",
  },
  surname: {
    en: "Surname",
    it: "Cognome",
  },
  quantity: {
    en: "Quantity",
    it: "Quantità",
  },
  unit_weight: {
    en: "Unit weight",
    it: "Peso unitario",
  },
  unit_price: {
    en: "Unit price",
    it: "Prezzo unitario",
  },
  discount: {
    en: "Discount",
    it: "Sconto",
  },
  goods: {
    en: "Goods",
    it: "Merce",
  },
  costs: {
    en: "Costs",
    it: "Costi",
  },
  options: {
    en: "Options",
    it: "Opzioni",
  },
  address: {
    en: "Address",
    it: "Indirizzo",
  },
  billing_address: {
    en: "Billing address",
    it: "Indirizzo di fatturazione",
  },
  street: {
    en: "Street",
    it: "Strada",
  },
  intersection: {
    en: "Intersection",
    it: "Intersection",
  },
  point_of_interest: {
    en: "Point of interest",
    it: "Punto d'interesse",
  },
  country: {
    en: "Country",
    it: "Nazione",
  },
  region: {
    en: "Region",
    it: "Regione",
  },
  sub_region: {
    en: "Sub region",
    it: "Provincia",
  },
  municipality: {
    en: "Municipality",
    it: "Città",
  },
  neighborhood: {
    en: "Neighborhood",
    it: "Quartiere",
  },
  postal_code: {
    en: "Postal code",
    it: "CAP",
  },
  house_number: {
    en: "House number",
    it: "Numero civico",
  },
  unit: {
    en: "Unit",
    it: "Unità",
  },
  billing_different_from_recipient: {
    en: "The billing address is different from the recipient address",
    it: "L'indirizzo di fatturazione è diverso da quello destinatario",
  },
  activation: {
    en: "Activation",
    it: "Attivazione",
  },
  billing: {
    en: "Billing",
    it: "Fatturazione",
  },
  to_work: {
    en: "process",
    it: "lavorare",
  },
  effective: {
    en: "Effective",
    it: "Effettivi",
  },
  estimated: {
    en: "Estimated",
    it: "Stimati",
  },
  blocked: {
    en: "Blocked",
    it: "Bloccati",
  },
  at_customer: {
    en: "at customer",
    it: "al cliente",
  },
  committed: {
    en: "Committed",
    it: "Impegnati",
  },
  digitals: {
    en: "Digital",
    it: "Digitali",
  },
  destination_address_book: {
    en: "Destination address book",
    it: "Rubrica destinazioni",
  },
  physical_good: {
    en: "Physical_good",
    it: "Bene fisico",
  },
  digital_good: {
    en: "Digital good",
    it: "Bene digitale",
  },
  calculated_logistics_goods_value: {
    en: "The value of the goods is calculated as the sum of the prices of the physical products excluding VAT",
    it: "Il valore merce è calcolato come la somma dei prezzi dei prodotti fisici iva esclusa",
  },
  calculated_logistics_cod_value: {
    en: "The cash on delivery value is calculated as the sum of the prices of all physical and digital products, VAT included",
    it: "Il valore contrassegno è calcolato come la somma dei prezzi di tutti i prodotti fisici e digitali, iva inclusa",
  },
  calculated_logistics_insurance_value: {
    en: "The insurance value is calculated as the sum of the prices of the physical products excluding VAT",
    it: "Il valore assicurazione è calcolato come la somma dei prezzi dei prodotti fisici iva esclusa",
  },

  //shipments
  my_shipments: { en: "My shipments", it: "Le mie spedizioni" },
  shipment: { en: "Shipment", it: "Spedizione" },
  shipments: { en: "Shipments", it: "Spedizioni" },
  shipments_today: { en: "Shipments today", it: "Spedizioni di oggi" },
  shipments_last_30: {
    en: "Shipments last 30 days",
    it: "Spedizioni ultimi 30 gg",
  },
  shipments_to_be_verfied: {
    en: "Shipments to be verified",
    it: "Spedizioni da verificare",
  },
  create_new_shipment: {
    en: "Create new shipment",
    it: "Crea nuova spedizione",
  },
  shipment_price: { en: "Shipment price", it: "Costo spedizione" },
  shipment_type: { en: "Shipment type", it: "Tipologia spedizione" },
  express: { en: "Express", it: "Espressa" },
  express_return: { en: "Return", it: "Reso" },
  postal: { en: "Postal", it: "Postale" },
  package_type: { en: "Package type", it: "Tipologia collo" },
  company_name: { en: "Company name", it: "Ragione sociale" },
  email: { en: "Email", it: "Email" },
  phone: { en: "Phone", it: "Telefono" },
  private: { en: "Private", it: "Privato" },
  gdo: { en: "GDO", it: "GDO" },
  ztl: { en: "ZTL", it: "ZTL" },
  search_locality: { en: "Search locality", it: "Cerca una località" },
  transport_document: {
    en: "Transport document",
    it: "Documento di trasporto",
  },
  number: {
    en: "Number",
    it: "Numero",
  },
  total_number: {
    en: "Total number",
    it: "Numero totale",
  },
  causal: {
    en: "Causal",
    it: "Causale",
  },

  //cod collections
  cod_collection: {
    en: "Cash on delivery collection",
    it: "Incasso contrassegno",
  },
  my_cod_collections: {
    en: "My cash on delivery collections",
    it: "I miei incassi contrassegno",
  },
  collection_date: {
    en: "Collection date",
    it: "Data di incasso",
  },
  cod_collection_details: {
    en: "Cash on delivery collection details",
    it: "Dettaglio incasso contrassegno",
  },

  //export
  my_exports: {
    en: "My exports",
    it: "Le mie esportazioni",
  },

  //orders
  my_orders: { en: "My orders", it: "I miei ordini" },
  order: { en: "Order", it: "Ordine" },
  create_new_order: {
    en: "Create new order",
    it: "Crea nuovo ordine",
  },
  n_products: {
    en: "Num Products",
    it: "Num prodotti",
  },
  store: {
    en: "Store",
    it: "Store",
  },
  barcodes: {
    en: "Barcode",
    it: "Barcode",
  },
  no_warehouse: {
    en: "No warehouse found.",
    it: "Nessun magazzino trovato",
  },
  impossible_create_order: {
    en: "Impossible to create order",
    it: "Impossibile creare l'ordine",
  },
  products: {
    en: "Products",
    it: "Prodotti",
  },
  product: {
    en: "Product",
    it: "Prodotto",
  },
  physical_goods: {
    en: "Physical goods",
    it: "Beni fisici",
  },
  all_products_are_completed: {
    en: "All products are completed",
    it: "Tutti i prodotti sono completati",
  },

  //warehouse
  warehouse: { en: "Warehouse", it: "Magazzino" },

  //collections
  my_collections: { en: "My collections", it: "I miei ritiri" },

  //kpi
  the_numbers: {
    en: "The numbers",
    it: "I numeri",
  },
  the_performances: {
    en: "The performances",
    it: "Le performance",
  },
  shipments_by_status: {
    en: "Shipments by status",
    it: "Spedizioni per stato",
  },
  shipments_by_carrier: {
    en: "Shipments by carrier",
    it: "Spedizioni per vettore",
  },
  average_delivery_time_per_service: {
    en: "Average delivery time per service",
    it: "Tempo medio di consegna per servizio",
  },
  working_days: {
    en: "Working days",
    it: "Giorni lavorativi",
  },
  taxed_weight: {
    en: "Taxed weight",
    it: "Peso tassato",
  },
  real_weight: {
    en: "Real weight",
    it: "Peso reale",
  },
  volumetric_weight: {
    en: "Volumetric weight",
    it: "Peso volumetrico",
  },
  ratio: {
    en: "Ratio",
    it: "Rapporto",
  },

  //status
  draft: { en: "Draft", it: "Bozza" },
  booked: { en: "Booked", it: "Prenotata" },
  confirmed: { en: "Confirmed", it: "Confermata" },
  received: { en: "Received", it: "Ricevuto" },
  cancelled: { en: "Cancelled", it: "Cancellato" },
  processing: { en: "Processing", it: "In lavorazione" },
  shipped: { en: "Shipped", it: "Spedito" },
  available_for_collection: {
    en: "Available for collection",
    it: "Disponibile per il ritiro",
  },
  in_transit: {
    en: "In trasit",
    it: "In transito",
  },
  out_of_delivery: {
    en: "Out of delivery",
    it: "In consegna",
  },
  delivery_exception: {
    en: "Problems with delivery",
    it: "Problema con la consegna",
  },
  failed_delivery: {
    en: "Failed delivery",
    it: "Tentativo di consegna fallito",
  },
  delivered: {
    en: "Delivered",
    it: "Consegnata",
  },
  delivered_pm: {
    en: "Delivered",
    it: "Consegnati",
  },
  info_rcvd: {
    en: "Info received",
    it: "Informazioni ricevute",
  },
  ret_sender: {
    en: "Return to sender",
    it: "Reso al mittente",
  },
  error: { en: "Error", it: "In errore" },
  coming_soon: { en: "Coming soon", it: "In arrivo" },
  in_stock: { en: "In stock", it: "In magazzino" },
  sold: { en: "Sold", it: "Venduto" },
  not_available_for_sale: {
    en: "Not available for sale (returned, damaged)",
    it: "Non disponibile alla vendita (resi, danneggiati)",
  },
  sale: {
    en: "Sale",
    it: "Vendita",
  },

  //actions
  create: { en: "Create", it: "Crea" },
  new: { en: "New", it: "Nuovo" },
  edit: { en: "Edit", it: "Modifica" },
  details: { en: "Details", it: "Dettaglio" },
  clone: { en: "Clone", it: "Duplica" },
  confirm: { en: "Confirm", it: "Conferma" },
  download: { en: "Download", it: "Scarica" },
  expand: { en: "Expand", it: "Espandi" },
  reduce: { en: "Reduce", it: "Riduci" },
  duplicate: { en: "Duplicate", it: "Duplica" },
  delete: { en: "Delete", it: "Elimina" },
  delete_selected: { en: "Delete selected", it: "Elimina selezionati" },
  confirm_selected: { en: "Confirm selected", it: "Conferma selezionati" },
  cancel: { en: "Cancel", it: "Annulla" },
  text_copied_correctly: {
    en: "Text copied correctly",
    it: "Testo copiato correttamente",
  },

  //modals
  irreversible_action: {
    en: "This action is irreversible.",
    it: "Questa azione è irreversibile.",
  },
  confirm_deletion: { en: "Confirm deletion", it: "Conferma eliminazione" },
  confirm_download: { en: "Confirm download", it: "Conferma download" },
  confirm_shipment: {
    en: "Confirm shipment",
    it: "Conferma spedizione",
  },
  confirm_order: {
    en: "Confirm order",
    it: "Conferma ordine",
  },
  confirm_export: {
    en: "Confirm export",
    it: "Conferma esportazione",
  },
  booking_confirmation: {
    en: "Booking confirmation",
    it: "Conferma prenotazione",
  },
  ask_confirm_deletion: {
    en: "Are you sure you want to delete",
    it: "Confermi di voler eliminare",
  },
  ask_confirm_export: {
    en: "Are you sure you want to export",
    it: "Confermi di voler esportare",
  },
  ask_confirm_confirmation: {
    en: "Are you sure you want to confirm",
    it: "Confermi di voler confermare",
  },
  ask_download_shipment_label: {
    en: "By clicking on the download button you will download the selected shipping label.",
    it: "Cliccando sul pulsante download scaricherai l'etichetta della spedizione selezionata.",
  },
  ask_download_shipment_labels: {
    en: 'By clicking on the download button you will download the labels of the shipments in "booked" and "confirmed" status selected.',
    it: 'Cliccando sul pulsante download scaricherai le etichette delle spedizioni in stato "prenotato" e "confermato" selezionate.',
  },
  ask_confirm_shipment: {
    en: "Are you sure you want to confirm the selected shipment?",
    it: "Sei sicuro di voler confermare la spedizione selezionata?",
  },
  ask_confirm_shipments: {
    en: "Are you sure you want to confirm the selected shipments?",
    it: "Sei sicuro di voler confermare le spedizioni selezionate?",
  },
  //errors
  nullfield: { en: "Required field", it: "Campo obbligatorio" },
  unequalValues: {
    en: "The values ​​do not match",
    it: "I valori non coincidono",
  },
};

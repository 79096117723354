export const buildLocationLabel = (location) => {
  const address = location.street || "";
  const houseNumber = location.houseNumber || "";
  const postalCode = location.postalCode || "";
  const municipality = location.municipality;
  const subRegion = location.subRegionName || "";
  const country = location.countryIsoCode3 || "";

  return `${address}, ${houseNumber}, ${postalCode} ${municipality} ${subRegion}, ${country}`;
};

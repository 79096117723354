export const orderDetailNormalizer = (
  orderDetail,
  accessTokenPayload,
  parentId
) => {
  orderDetail.articleId = orderDetail.article?.id;
  orderDetail.orderId = orderDetail.orderId || parentId;
  orderDetail.quantity = orderDetail.quantity?.code || orderDetail.quantity || 1;
  return orderDetail;
};

export const calculateDimensions = (orderDetail) => {
  const newOrderDetail = { ...orderDetail };
  newOrderDetail.unitPrice = orderDetail.article?.price || "";
  newOrderDetail.totalPrice =
    parseFloat(orderDetail.article?.price || 0) *
      parseInt(newOrderDetail.quantity?.code) -
      parseInt(newOrderDetail.quantity?.code) *
        parseFloat(newOrderDetail.discount || 0) || "";
  newOrderDetail.unitWeight = orderDetail.article?.weight || "";
  newOrderDetail.totalWeight =
    parseFloat(orderDetail.article?.weight) *
      parseInt(newOrderDetail.quantity?.code) || "";
  return newOrderDetail;
};

// //#region Index columns
export const columns = [
  // {
  //   position: 1,
  //   Header: "",
  //   accessor: "article.imageUrl",
  //   // Cell: ({ cell }) => {
  //   //   console.log(cell.row.original.article.imageUrl);
  //   // },
  // },
  {
    position: 1,
    Header: "Barcode",
    accessor: "barcode",
  },
  {
    position: 2,
    Header: "Nome",
    accessor: "article.name",
  },
  {
    position: 3,
    Header: "Quantità",
    accessor: "quantity",
  },
  {
    position: 5,
    Header: "Peso unitario",
    accessor: "unitWeight",
    Cell: ({ cell }) => {
      return cell.row.original.unitWeight
        ? cell.row.original.unitWeight.toFixed(3)
        : "-";
    },
  },
  {
    position: 6,
    Header: "Peso totale",
    accessor: "totalWeight",
    Cell: ({ cell }) => {
      return cell.row.original.totalWeight
        ? cell.row.original.totalWeight.toFixed(3)
        : "-";
    },
  },
  {
    position: 7,
    Header: "Prezzo unitario",
    accessor: "unitPrice",
    Cell: ({ cell }) => {
      return cell.row.original.unitPrice
        ? cell.row.original.unitPrice.toFixed(2)
        : "-";
    },
  },
  {
    position: 8,
    Header: "Sconto",
    accessor: "discount",
    Cell: ({ cell }) => {
      return cell.row.original.discount
        ? cell.row.original.discount.toFixed(2)
        : "-";
    },
  },
  {
    position: 9,
    Header: "Prezzo totale",
    accessor: "totalPrice",
    Cell: ({ cell }) => {
      return cell.row.original.totalPrice
        ? cell.row.original.totalPrice.toFixed(2)
        : "-";
    },
  },
];
// //#endregion

export const Checkbox = ({
  label,
  checked,
  onChange,
  name,
  disabled = false,
  className = "",
}) => {
  return (
    <label className={`checkbox`}>
      <input
        className={className}
        name={name}
        type="checkbox"
        checked={checked}
        onChange={onChange}
        disabled={disabled}
      />
      <span
        className={`checkmark ${
          disabled ? "cursor-default" : "cursor-pointer"
        } ${className}`}
      ></span>
      {label && <span className="span-1">{label}</span>}
    </label>
  );
};

import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Users from "../pages/Users/Index";
import Shipments from "../pages/Shipments";
import Customers from "../pages/Customers";
import Organizations from "../pages/Organizations";
import Affiliates from "../pages/Afiliates";
import CreateEditShipment from "../pages/Shipments/createEdit";
import DetailsShipment from "../pages/Shipments/details";
import ShipmentRate from "../pages/Shipments/rate";
import Zones from "../pages/Locations/Zones";
import { Upload as UploadZone } from "../pages/Locations/Zones/Upload";
import { UploadZoneProvider } from "../pages/Locations/providers/Upload";
import Addresses from "../pages/Addresses";
import AddressBook from "../pages/AddressBook";
import Contracts from "../pages/Contracts";
import ContractServices from "../pages/ContractServices";
import CreateEditContractService from "../pages/ContractServices/createEdit";
import ParcelTemplates from "../pages/ParcelTemplates";
import Tracking from "../pages/Tracking";
import Collections from "../pages/Collections";
import User from "../pages/Profiles/User";
import CarriersIntegrations from "../pages/CarriersIntegrations";
import LandingPage from "../pages/LandingPage";
import StoresIntegrations from "../pages/StoresIntegrations";
import LogisticIntegrations from "../pages/LogisticIntegrations";
import Articles from "../pages/Articles";
import ArticleDetails from "../pages/Articles/Details";
import { CreateEdit as CreateEditArticle } from "../pages/Articles/CreateEdit";
import Orders from "../pages/Orders";
import { CreateEditArticleProvider } from "../pages/Articles/providers/CreateEdit";
import { OrderHandlerProvider } from "../pages/Orders/providers/Handler";
import Warehouses from "../pages/Warehouses";
import { CreateEditWarehouseProvider } from "../pages/Warehouses/providers/CreateEdit";
import { CreateEdit as CreateEditWarehouse } from "../pages/Warehouses/CreateEdit";
import IncomingGoods from "../pages/IncomingGoods";
import { CreateEditIncomingGoodProvider } from "../pages/IncomingGoods/providers/CreateEdit";
import { CreateEdit as CreateEditIncomingGood } from "../pages/IncomingGoods/CreateEdit";
import { Details as DetailsIncomingGood } from "../pages/IncomingGoods/Details";
import {
  faHouse,
  faMapLocationDot,
  faTruckFast,
  faFileContract,
  faPuzzle,
  faAddressCard,
  faUser,
  faUsers,
  faHandHoldingBox,
  faBoxes,
  faAddressBook,
  faInventory,
  faBagsShopping,
  faWarehouse,
  faFileImport,
  faMoneyCheckDollarPen,
  faFileExport,
} from "@awesome.me/kit-c1b85ff10f/icons/classic/light";
import { IndexShipmentsProvider } from "../pages/Shipments/providers/Index";
import { IndexCodCollectionsProvider } from "../pages/CODCollections/providers/Index";
import CODCollections from "../pages/CODCollections";
import CODCollectionHandler from "../pages/CODCollections/CODCollectionHandler";
import { DetailsCodCollectionProvider } from "../pages/CODCollections/providers/Details";
import Exports from "../pages/Export";
import { IndexExportsProvider } from "../pages/Export/providers/Index";
import Manifest from "../pages/Manifest";
import { IndexOrdersProvider } from "../pages/Orders/providers/Index";
import OrderHandler from "../pages/Orders/OrderHandler";
import DDTShipment from "../pages/DDTShipment";
import { DDTProvider } from "../pages/DDTShipment/providers/Index";

const authProtetedRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
    name: "Homepage",
    icon: faHouse,
    template: true,
  },
  //#region localities
  {
    path: "/locations/zones",
    element: <Zones />,
    name: "Zone",
    icon: faMapLocationDot,
    template: true,
  },
  {
    path: "/locations/zones/upload",
    element: (
      <UploadZoneProvider autosave={true}>
        <UploadZone />
      </UploadZoneProvider>
    ),
    name: "Importazione zona",
    icon: faMapLocationDot,
    template: true,
  },
  //#endregion
  //#region authentications
  {
    path: "/user-profile",
    element: <User />,
    name: "Profilo utente",
    icon: faUser,
    template: true,
  },

  {
    path: "/users",
    element: <Users />,
    name: "Utenti",
    icon: faUsers,
    template: true,
  },
  //#endregion
  //#region shipments
  {
    path: "/shipments",
    element: (
      <IndexShipmentsProvider>
        <Shipments />
      </IndexShipmentsProvider>
    ),
    name: "Spedizioni",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/create",
    element: <CreateEditShipment />,
    name: "Nuova spedizione",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/edit/:id",
    element: <CreateEditShipment />,
    name: "Modifica spedizione",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/clone/:id",
    element: <CreateEditShipment />,
    name: "Clona spedizione",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/return/:id",
    element: <CreateEditShipment />,
    name: "Reso spedizione",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/details/:id",
    element: <DetailsShipment />,
    name: "Dettaglio spedizione",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/rate/:id",
    element: <ShipmentRate />,
    name: "Scegli tariffa",
    icon: faTruckFast,
    template: true,
  },
  {
    path: "/shipments/manifest",
    element: <Manifest />,
    template: false,
  },
  {
    path: "/shipments/ddt/:id",
    element: (
      <DDTProvider>
        <DDTShipment />
      </DDTProvider>
    ),
    template: false,
  },
  {
    path: "/collections",
    element: <Collections />,
    name: "Ritiri",
    icon: faHandHoldingBox,
    template: true,
  },
  {
    path: "/parcel-templates",
    element: <ParcelTemplates />,
    name: "Template colli",
    icon: faBoxes,
    template: true,
  },
  //#endregion
  //#region COD Collections
  {
    path: "/cod-collections",
    element: (
      <IndexCodCollectionsProvider>
        <CODCollections />
      </IndexCodCollectionsProvider>
    ),
    name: "Contrassegni",
    icon: faMoneyCheckDollarPen,
    template: true,
  },
  {
    path: "/cod-collections/details/:id",
    element: (
      <DetailsCodCollectionProvider>
        <CODCollectionHandler />
      </DetailsCodCollectionProvider>
    ),
    name: "Contrassegni",
    icon: faMoneyCheckDollarPen,
    template: true,
  },
  //#endregion
  //#region Contracts
  {
    path: "/contracts",
    element: <Contracts />,
    name: "Contratti",
    icon: faBoxes,
    template: true,
  },
  {
    path: "/contract-services",
    element: <ContractServices />,
    name: "Servizi",
    icon: faFileContract,
    template: true,
  },
  {
    path: "/contract-services/create",
    element: <CreateEditContractService />,
    name: "Nuovo servizio",
    icon: faFileContract,
    template: true,
  },
  {
    path: "/contract-services/edit/:id",
    element: <CreateEditContractService />,
    name: "Modifica servizio",
    icon: faFileContract,
    template: true,
  },
  {
    path: "/contract-services/clone/:id",
    element: <CreateEditContractService />,
    name: "Clona servizio",
    icon: faFileContract,
    template: true,
  },
  //#endregion
  //#region registry
  {
    path: "/customers",
    element: <Customers />,
    name: "Clienti",
    icon: faAddressCard,
    template: true,
  },
  {
    path: "/affiliates",
    element: <Affiliates />,
    name: "Affiliati",
    icon: faAddressCard,
    template: true,
  },
  {
    path: "/organizations",
    element: <Organizations />,
    name: "Organizzazioni",
    icon: faAddressCard,
    template: true,
  },
  {
    path: "/addresses",
    element: <Addresses />,
    name: "Indirizzi",
    icon: faAddressBook,
    template: true,
  },
  {
    path: "/address-book",
    element: <AddressBook />,
    name: "Rubrica",
    icon: faAddressBook,
    template: true,
  },
  //#endregion
  //#region integrations
  {
    path: "/carriers-integrations",
    element: <CarriersIntegrations />,
    name: "Integrazioni vettori",
    icon: faPuzzle,
    template: true,
  },
  {
    path: "/stores-integrations",
    element: <StoresIntegrations />,
    name: "Integrazioni stores",
    icon: faPuzzle,
    template: true,
  },
  {
    path: "/logistic-integrations",
    element: <LogisticIntegrations />,
    name: "Integrazioni logistiche",
    icon: faPuzzle,
    template: true,
  },
  //#endregion
  //#region logistic
  {
    path: "/articles",
    element: <Articles />,
    name: "Prodotti",
    icon: faInventory,
    template: true,
  },
  {
    path: "/articles/create",
    element: (
      <CreateEditArticleProvider autosave={true}>
        <CreateEditArticle />
      </CreateEditArticleProvider>
    ),
    name: "Nuovo prodotto",
    icon: faInventory,
    template: true,
  },
  {
    path: "/articles/edit/:id",
    element: (
      <CreateEditArticleProvider autosave={true}>
        <CreateEditArticle />
      </CreateEditArticleProvider>
    ),
    name: "Modifica prodotto",
    icon: faInventory,
    template: true,
  },
  {
    path: "/articles/details/:id",
    element: <ArticleDetails />,
    name: "Dettaglio prodotto",
    icon: faInventory,
    template: true,
  },
  {
    path: "/orders",
    element: (
      <IndexOrdersProvider>
        <Orders />
      </IndexOrdersProvider>
    ),
    name: "Ordini",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/orders/create",
    element: (
      <OrderHandlerProvider autosave={true}>
        <OrderHandler />
      </OrderHandlerProvider>
    ),
    name: "Ordini",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/orders/edit/:id",
    element: (
      <OrderHandlerProvider autosave={true}>
        <OrderHandler />
      </OrderHandlerProvider>
    ),
    name: "Ordini",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/orders/clone/:id",
    element: (
      <OrderHandlerProvider autosave={true}>
        <OrderHandler />
      </OrderHandlerProvider>
    ),
    name: "Ordini",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/orders/details/:id",
    element: (
      <OrderHandlerProvider>
        <OrderHandler />
      </OrderHandlerProvider>
    ),
    name: "Ordini",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/warehouses",
    element: <Warehouses />,
    name: "Magazzini",
    icon: faWarehouse,
    template: true,
  },
  {
    path: "/warehouses/create",
    element: (
      <CreateEditWarehouseProvider>
        <CreateEditWarehouse />
      </CreateEditWarehouseProvider>
    ),
    name: "Nuovo magazzino",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/warehouses/edit/:id",
    element: (
      <CreateEditWarehouseProvider>
        <CreateEditWarehouse />
      </CreateEditWarehouseProvider>
    ),
    name: "Modifica magazzino",
    icon: faBagsShopping,
    template: true,
  },
  {
    path: "/incoming-goods",
    element: <IncomingGoods />,
    name: "Bolle entrata merce",
    icon: faFileImport,
    template: true,
  },
  {
    path: "/incoming-goods/create",
    element: (
      <CreateEditIncomingGoodProvider autosave={true}>
        <CreateEditIncomingGood />
      </CreateEditIncomingGoodProvider>
    ),
    name: "Nuova bolla entrata merce",
    icon: faFileImport,
    template: true,
  },
  {
    path: "/incoming-goods/edit/:id",
    element: (
      <CreateEditIncomingGoodProvider autosave={true}>
        <CreateEditIncomingGood />
      </CreateEditIncomingGoodProvider>
    ),
    name: "Dettaglio bolla entrata merce",
    icon: faFileImport,
    template: true,
  },
  {
    path: "/incoming-goods/details/:id",
    element: (
      <CreateEditIncomingGoodProvider>
        <DetailsIncomingGood />
      </CreateEditIncomingGoodProvider>
    ),
    name: "Dettaglio bolla entrata merce",
    icon: faFileImport,
    template: true,
  },
  //#endregion
  //#region exports
  {
    path: "/exports",
    element: (
      <IndexExportsProvider>
        <Exports />
      </IndexExportsProvider>
    ),
    name: "Esportazioni",
    icon: faFileExport,
    template: true,
  },
  //#endregion
];

const publicRoutes = [
  { path: "/tracking", element: <Tracking />, header: true },
  { path: "/tracking/:reference1", element: <Tracking />, header: true },
  {
    path: "/tracking/:reference1/:reference2",
    element: <Tracking />,
    header: true,
  },
  { path: "/", element: <LandingPage /> },
  { path: "*", element: <LandingPage /> },
];

export { authProtetedRoutes, publicRoutes };
